/// Bootstrap 4
@import "bootstrap";

/// Font Awesome 5
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/core";
@import "~@fortawesome/fontawesome-free/scss/variables";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

/// Owl Carousel
// @import "~owl.carousel/src/scss/owl.carousel";

/// Hamburgers

// @import "hamburgers";

/// CSS Shake
@import "~csshake/dist/csshake";

/// Lightbox
@import "~imagelightbox/src/imagelightbox";

// Toastr
@import "~toastr/toastr";

/// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap&subset=latin-ext');

///Cookie
// cookies info

.cookieConsentContainer {
  @include  border-radius(3px);
  z-index: 4999;
  width: 350px;
  min-height: 20px;
  box-sizing: border-box;
  padding: 30px 30px 30px 30px;
  background: #232323;
  overflow: hidden;
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: none;
  line-height: 1em;
  .cookieTitle h4 {
    color: $white;
    font-size: 22px;
    line-height: 20px;
    display: block;
  }
  .cookieDesc p {
    margin: 0;
    padding: 0;
    color: $white;
    font-size: 13px;
    line-height: 20px;
    display: block;
    margin-top: 10px;
  }
  .cookieDesc a {
    color: $white;
    text-decoration: underline;
  }
  .cookieButton a {
   @include  border-radius(10px);
    display: inline-block;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    margin-top: 14px;
    background: $color-primary;
    box-sizing: border-box;
    padding: 15px 24px;
    text-align: center;
    transition: background 0.3s, color 0.3s;
    &:hover {
      cursor: pointer;
      color:$white;
      background: $black;
    }
  }
}

@media (max-width: 980px) {
  .cookieConsentContainer {
    bottom: 0px !important;
    left: 0px !important;
    width: 100%  !important;
  }
}