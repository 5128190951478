.main-footer{
  position: relative;
  width: 100%;
  background: lighten($color-dark, 5%);
  padding: 90px 0 0;
  overflow-x: hidden;
  &:before{
    content: url('../img/footer-element.svg');
    position: absolute;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: 380px;
    z-index: 0;
  }
  .back-to-top{
    scroll-behavior: smooth;
    background: $color-white;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    // @include transition(all 250ms ease-in-out);
    .arrow-icon{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @for $i from 1 through 6 {
    h#{7 - $i} {
      color: $color-white;
      font-weight: 600;
    }
  }
  .footer-menu{
    margin: 0;
    padding: 0;
    columns: 2;
    &.contact-details{
      columns: 1;
    }
    .child{
      width: 100%;
      list-style-type: none;
      padding-left: 0;
      line-height: 32px;
      .link{
        position: relative;
        color: lighten($color-dark, 50%);
        font-weight: 600;
        // @include transition(all 250ms ease-in-out);
        .link-icon{
          position: relative;
          top: -2px;
          float: left;
          width: 30px;
        }
        .link-text{
          position: relative;
          z-index: 2;
        }
        &:hover{
          color: $color-primary;
          text-decoration: none;
        }
      }
    }
  }
  .copyrights{
    margin-top: 90px;
    background: $color-dark;
    padding: 30px 0;
    .single-link{
      float: left;
      margin-right: 30px;
      @media (max-width: $md){
        margin-right: 0;
        width: 100%;
        margin-bottom: 10px;
      }
      .link-icon{
        position: relative;
        top: -2px;
        float: left;
        width: 30px;
        @media (max-width: $md){
          float: none;
          margin-right: 20px;
        }
      }
      .link-text{
        position: relative;
        z-index: 2;
        color: $color-white;
        font-weight: 700;
      }
      &:hover{
        color: $color-primary;
        text-decoration: none !important;
      }
    }
  }
}