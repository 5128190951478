#page {
  margin-top: 75px;
  h1 {
    font-weight: 800;
    text-transform: uppercase;
  }
  img:not(.img-fluid){
    max-width: 100%;
    height: auto !important;
  }
}

