/// Aspect Ratio

@mixin aspect-ratio($ratio: 1 1) {
  $width: 100%;
  $height: percentage(nth($ratio, 2) / nth($ratio, 1));
  width: $width;
  height: 0;
  padding-bottom: $height;
}

/// Transitions

@mixin transition($property, $duration, $easing: linear) {
  transition: $property $duration $easing;
  -webkit-transition: $property $duration $easing;
  -moz-transition: $property $duration $easing;
}

/// Border Radius

@mixin border-radius($radius) {
  border-radius: $radius;
}

/// Gradient

@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

/// Absolute Centering

@mixin center($position) {
  position: absolute;

  @if $position == 'vertical' {
    top: 50%;
    transform: translateY(-50%);
  }
  @else if $position == 'horizontal' {
    left: 50%;
    transform: translate(-50%);
  }
  @else if $position == 'both' {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/// Gradient

@mixin gradient($top_color, $bottom_color) {
  background-image: -webkit-linear-gradient(top, $top_color 0%, $bottom_color 100%);
  background-image: linear-gradient(to bottom, $top_color 0%, $bottom_color 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($top_color)}', endColorstr='#{ie-hex-str($bottom_color)}', GradientType=0); // IE9 and down
}