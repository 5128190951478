$dark_mode_black_bg: #0d0c09;

main.black {
    section {
        &.black-friday-top {
            position:relative;
            padding-top:0;
            padding-bottom:0;
            &:after {
                content: '';
                position:absolute;
                bottom:0;
                left:0;
                right:0;
                height:50px;
                background:linear-gradient(180deg, transparent, $dark_mode_black_bg);
            }
            img {
                width:100%!important;
                max-width:100%!important;
                height:auto!important;
            }
        }
        
    }
    background: $dark_mode_black_bg;
    color:#fff;
    overflow:hidden;
    .section-title {
        color: #fff;
    }

    .product-box {
        background-color: #232323;
        .image {
            background: linear-gradient(180deg,#4F452B,#232323)
        }
        .product-name {
            color: #fff;
            &:hover {
                color: #f7ac05;
            }
        }
    }
}

@media(max-width:1024px) {
    main.black {
        section {
            &.black-friday-top {
                img {
                    width:250%!important;
                    max-width:250%!important;
                    height:auto!important;
                    transform:translateX(-31%);
                }
            }
        }
    }
}