/// Colors

$color-bg: #F5F5F5;
$color-dark: #131313;
$color-white: #FFF;
$color-primary: #F18916;
$color-secondary: #F7AC05;
$color-gray: #949494;
$gray_light: #eee;
$gray_lighter: #f6f6f6;
$orange_dark: #f06115;

/// Fonts

$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'Open Sans', sans-serif;

// Variables
$input_height: 42px;
$border_radius: 6px;
$gray_light: #eee;
$gray_lighter: #f6f6f6;

/// Sizing

$border-radius: 3px;

/// Breakpoints

$xxxs: 0;
$xxs: 320px;
$xs: 568px;
$sm: 667px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;
$xxxl: 1600px;
$fullhd: 1920px;