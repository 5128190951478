//colors
$light-orange: #F7AC05;
$dark-orange: #F18916;
$light-blue: #64D9F7;
$dark-blue: #1CAACD;
$light-green: #00D100;
$dark-green: #00A813;
$white: #FFFFFF;
$white-dark: #f8f8f8;
$light-red: #D10000;
$dark-red: #BE0000;
$dark-grey: #626262;
$light-grey: #909090;
$lighter-grey: #E6E6E6;
$grey: #C1C1C1;
$black: #000;
$black-light: #0F0F0F;
$black-ligter: #4D4D4D;

$light-purple: #9117C7;
$dark-purple: #7308A2;
$label-blue-light:#1277dc;
$label-blue-dark:#0d65bd;
//gradients
$gradient-orange: linear-gradient(to bottom, $light-orange, $dark-orange);
$gradient-dark-orange: linear-gradient(to bottom, $dark-orange, $light-orange);
$gradient-blue: linear-gradient(to bottom, $light-blue, $dark-blue);
$gradient-green: linear-gradient(to bottom, $light-green, $dark-green);
$gradient-red: linear-gradient(to bottom, $light-red, $dark-red);
$gradient-grey: linear-gradient(to bottom, $lighter-grey, $white);
$gradient-black: linear-gradient(90deg, rgba(0,0,0,1) 45%, rgba(0,0,0,0) 100%);
$gradient-white: linear-gradient(90deg, rgba(255,255,255,1) 45%, rgba(255,255,255,0) 100%);
$gradient-black-bottom: linear-gradient(to bottom, $black-ligter, $black-light);
$gradient-black-2: linear-gradient(0deg, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%);
$gradient-white-2: linear-gradient(0deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%);


$gradient-orange-right: linear-gradient(90deg, $light-orange, $dark-orange);

.bg-white-dark {
    background-color: $white-dark!important;
}

.text-right {
    text-align: right;
}

.input-group {
    .input-group-text {
        background-color:#fff;
    }
    input[type=number],
    input[type=text] {
        text-align:center;
    }
}

.award-logo {
    max-width:95px!important;
}

body {
    font-family: 'Red Hat Display', sans-serif;
    background-color: $white-dark;
}

a {
    color:$dark-orange;
    transition: all ease-out .3s;
    &:hover {
        color:$light-orange;
        text-decoration: none;
    }
}

header { 
    // display:flex;
    // align-content: center;
    // justify-content: center;
    background:$black;
    .container-fluid {
        max-width:1760px;
        .row {
            // min-height:118px;
        }
    }
    nav.main-menu {
        ul{
            margin:0;
            padding:0;
            list-style: none;
            li {
                // float:left;
                padding:0 12px;
                a {
                    color:$white;
                    text-decoration:none;
                    font-size:18px;
                    font-weight:500;
                    &:hover, &.active {
                        color:$light-orange;
                    }
                }
                .expand-menu {
                    display:none;
                }
            }
        }
    }
    form {
        .search-input {
            border-radius:15px;
            border:0px;
            height:46px;
            width:100%;
            max-width:300px;
            background:$white url(../../public/assets/img/icon-find.png) no-repeat calc(100% - 10px);
            padding:0 10px;
        }
    }
    .dropdown {
        float:left;
        &:not(.has-megamenu)
        {
            margin:0 15px;
        }
        .btn {
            &:hover {
                color:$light-orange;
            }
        }
        h5 {
            font-weight:600;
            padding-bottom:5px;
            
        }
        ul {
            padding-top: 15px!important;
            display: inline-block;
            border-top: 1px solid $light-orange;
        }
    }

    .btn-dropdown {
        background: $black;
        color: $white;
        border-radius:15px;
        border:1px solid $light-orange;
        font-size:15px;
        font-weight:700;
        min-width:78px;
        padding: 12px 9px;
        box-shadow: 0 0 1px 0px $light-orange inset, 0 0 1px 0px $light-orange;
    }

    .btn-cart {
        position:relative;
        padding:0;
        margin:0 15px;
        span {
            position:absolute;
            left:20px;
            bottom:0;
            width:21px;
            height:21px;
            font-size:11px;
            line-height:21px;
            font-weight:500;
            display:inline-block;
            border-radius:100%;
            background: $gradient-orange;
            color:$black;

        }
    }
    .btn-login {
        margin:0 15px;
    }
}

section {
    padding: 50px 0;
    &.slider{
        padding:0;
        max-width:1920px;
        margin:0 auto;
        .slick-prev, .slick-next {
            position:absolute;
            top:50%;
            transform:translateY(-50%);
            background:rgba(153,153,153,.15);
            color:$white;
            border-radius:35px;
            border:0;
            padding: 16px 35px;
            line-height:30px;
            font-size:15px;
            font-weight:600;
            text-align:center;
            z-index:9999;
            &:hover {
                background:$light-orange;
                color:$black;
            }
        }
        .slick-prev {
            left:25px;
        }
        .slick-next {
            right:25px;
            img {
                transform: rotate(180deg);
            }
        }
        .slick-dots {
            border-radius:35px;
            margin:0;
            padding:20px 50px;
            list-style: none;
            // background:rgba(255,255,255,.85);
            display:block;
            overflow:hidden;
            position:absolute;
            bottom:20px;
            left:50%;
            transform:translateX(-50%);
            backdrop-filter: blur(15px);
            line-height:1;
            li {
                display:inline-block;
                padding:0;
                margin:0;
                button {
                    border:0;
                    padding:0;
                    margin:4px 16px 0;
                    background:$light-orange;
                    border-radius:100%;
                    width:8px;
                    height:8px;
                    text-indent:-9999px;
                }
                &.slick-active {
                    button {
                        background:$white;
                        border:4px solid $light-orange;
                        width:16px;
                        height:16px;
                        margin-top:0;
                    }
                }
            }
        }
        .slide {
            height:70vh;
            min-height:300px;
            max-height:625px;
            position:relative;
            background-size: contain;
            &.slide-dark {
                background-color: $black;
                background-repeat: no-repeat;
                background-position: top right;
                color:$white;
                &:before {
                    content:'';
                    top:0;
                    left:0;
                    right:5%;
                    bottom:0;
                    position:absolute;
                    background:$gradient-black;
                }
                .row .slide-left {
                    // background-image: $gradient-black;
                    height:625px;
                    z-index:10;
                }
            }
            &.slide-light {

                background-color: $white;
                background-repeat: no-repeat;
                background-position: top right;
                color:$black;
                &:before {
                    content:'';
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    position:absolute;
                    background:$gradient-white;
                }
                .row .slide-left {
                    // background-image: $gradient-white;
                    // height:625px;
                    z-index:10;
                }
            }
            h2 {
                margin-bottom: 1.5rem;
            }
            img {
                // margin-bottom: 3.125rem;
            }
            p {
                margin-bottom: 3.125rem;
            }
        }
    }

    &.info {
        padding:0;
        p {
            font-weight:700;
            line-height:64px;
            font-size:18px;
            span{
                display:inline-block;
                margin:0 15px;
            }
        }
    }

    &.newsletter {
        padding:40px 0;
        .container {
            background:$gradient-orange-right;
            border-radius: 70px;
            padding:55px;
        }
        h2 {
            color:$white;
            font-size:40px;
            font-weight:900;
            text-shadow: 0px 3px 6px rgba(0,0,0,0.16);
        }
        p{
            font-size:25px;
            font-weight:500;
        }
        input[type=email] {
            border-radius:15px;
            border:0px;
            height:42px;
            padding:0 10px;
            width:100%;
            max-width:540px;
            margin-right:10px;
        }
        label {
            margin-top:20px;
        }
        a {
            color: $black;
            &:hover {
                color: $black-ligter;
            }
        }
    }

    &.universe {
        background:$white;
        padding:50px 0;
        .logo {
            background: $gradient-grey;
            border-radius:25px;
            box-shadow: 0 5px 6px rgba(0,0,0,.1);
            margin-bottom:15px;
            min-height:180px;
            overflow:hidden;
            img {
                max-width:85%;
                height:auto;
                margin-top:30px;
                margin-bottom:30px;
                display:block;
            }
        }
    }

    &.blog {
        
        padding:80px 0;
    
        .blog-box {
            background: $white;
            border-radius: 3.125rem;
            overflow: hidden;
            margin-top: 50px;
            padding: 0 25px 25px;
            height: calc(100% - 50px);
            position:relative;
            .image {
                margin: 0 -25px;
            }
            .content {
                margin-bottom:20px;
                float:left;
                h3 {
                    font-size: 18px;
                    font-weight: 700;
                    margin: 30px 0 20px;
                }
                p {
                    font-size: 1rem;
                    padding-bottom:20px;
                }
            }
            .btn {
                position:absolute;
                right:25px;
                bottom:25px
            }
        }
    }
    &.product-short-desc {
        background-repeat:no-repeat;
        background-position:top center;
        min-height: 777px;
        .product-gallery-desc {
            overflow: hidden;
            padding:35px;
            background:rgba(255,255,255,.95);
            border-radius:35px;
            .image {
                background:$gradient-grey;
                border-radius:35px;
                box-shadow: 0 5px 6px rgba(0,0,0,.1);
                overflow:hidden;
                .thumbnails {
                    padding:25px 20px;
                    cursor: pointer;
                    img {
                        margin:0 5px;
                        max-height:85px;
                        width:auto;
                        border-radius:30px;
                    }
                }
            }
            .big-pic {
                a {
                    min-height:380px;
                    background-position:center;
                    background-size:cover;
                }
                img {
                    max-height: 380px;
                    width:auto;
                }
            }
            .slick-prev, .slick-next {
                position:absolute;
                top:50%;
                transform:translateY(-50%);
                background:rgba(255,255,255,1);
                color:$white;
                border-radius:35px;
                border:0;
                padding: 16px 35px;
                line-height:30px;
                font-size:15px;
                font-weight:600;
                text-align:center;
                z-index:9999;
                &:hover {
                    background:$light-orange;
                    color:$black;
                }
            }
            .slick-prev {
                left:25px;
            }
            .slick-next {
                right:25px;
                img {
                    transform: rotate(180deg);
                }
            }
            h2 {
                font-weight:900;
                margin:20px 0 15px;
            }
            p {
                font-size:20px;
            }
            .price {
                color: #f7ac05;
                font-size: 25px;
                font-weight: 900;
                &.old_price {
                    color: #cecece;
                    font-size: 18px;
                    text-decoration: line-through;
                    clear: both;
                    display: inline;
                    text-decoration-color:$light-red;
                    font-style: italic;
                }
                &.sale_price {
                    color:$light-red;
                }
            }
            .freeshipping-info {
                margin-bottom:15px;
            }
            .buttons {
                .form-select {
                    border-radius:15px;
                    line-height:42px;
                }
            }
        }
        .product-player-time {
            background:$white;
            border-radius: 35px;
            box-shadow: 0 5px 6px rgba(0,0,0,.1);
            padding:30px 60px;
            font-size: 25px;
            margin-top:25px;
            img {
                margin-right:10px;
            }
        }
    }
    &.product-description {
        .desc-title {
            font-size:25px;
            font-weight:500;
            padding:35px
        }
        .desc-content {
            padding:35px;
            font-size:20px;
            img {
                max-width:100%;
                height:auto;
            }
            ul {
                // list-style:none;
                // padding:0;
                margin:0;
                column-count: 3;
                li {
                    margin:0;
                    padding:0;
                    max-width: 280px;
                    ul {
                        column-count: 1;
                    }
                }
                ul {
                    column-count: 1;
                }
            }
            .download-item {
                background:$white;
                box-shadow: 0 5px 6px rgba(0,0,0,.1);
                border-radius:15px;
                padding:15px;
                overflow: hidden;
                img {
                    float:left;
                }
                h5 {
                    margin:10px 0 30px;
                }
            }
        }
    }
}

section {
    &.cart {
        background-color:$white;
        h5 {
            font-size:27px;
            margin-bottom:0;
        }
        .price {
            color:$light-orange;
            font-size:35px;
            font-weight:700;
            line-height:1;
            &.old_price {
                color: #cecece;
                font-size: 18px;
                text-decoration: line-through;
                clear: both;
                display: inline;
                text-decoration-color:$light-red;
                font-style: italic;
            }
            &.sale_price {
                color:$light-red;
            }
        }
        
        .cart-title {
            margin-bottom:85px;
            // margin-left:-1.5rem;
        }
        .product-cart {
            padding-bottom:30px;
            margin-bottom:40px;
            position:relative;
            &.error {
                border:2px solid $dark-red;
            }
            &:before {
                content:'';
                position:absolute;
                bottom:0;
                left:0;
                width:100%;
                height:1px;
                background:$grey;
            }
            h5 {
                display:inline-block;
                margin-top:20px;
            }
            button {
                position:absolute;
                right:0;
                top:-8px;
            }
            .image {
                background: $gradient-grey;
                border-radius: 20px;
                box-shadow: 0 5px 6px rgba(0,0,0,.1);
                margin-bottom: 15px;
                // margin-left:-1.5rem;
                text-align:center;
            }
            .details {
                margin-top:25px;
                p{
                    margin-bottom:35px;
                }
                input[type=number],
                input[type=text] {
                    width:45px;
                    max-width:45px;
                }
            }
        }
        .cart-summary {
            overflow:hidden;
            background-color: $white-dark;
            border-radius:15px;
            padding:40px;
            .cart-info {
                margin-top:15px;
                padding-top:15px;
                // border-top:1px solid $dark-grey;
                position:relative;
                &:before {
                    content:'';
                    display:block;
                    width:calc(100% - 24px);
                    height:1px;
                    background:$dark-grey;
                    position:absolute;
                    top:0;
                    left:12px;
                }
                p {
                    font-size:15px;
                    font-weight:400;
                    margin-bottom:45px;
                }
            }
            .btn {
                width: 100%;
                margin-bottom: 15px;
            }        
        }
        .payments {
            margin-top:15px;
        }
        .cart-steps {
            list-style: none;
            padding:0;
            margin:0;
            float:right;
            li {
                float:left;
                width:26px;
                height:26px;
                background:$grey;
                border-radius:100%;
                &.active {
                    background:$light-orange;
                }
                &:nth-child(2) {
                    margin:0 38px;
                    position:relative;
                    &:before, &:after {
                        content:'';
                        position:absolute;
                        top:50%;
                        width:38px;
                        height:3px;
                        background:$grey;
                    }
                    &:before {
                        transform:translate(-100%, -50%);
                        left:0;
                    }
                    &:after {
                        transform:translate(100%, -50%);
                        right:0;
                    }
                }
            }
        }
    }
}

section {
    &.featured-products {
        .featured-product-slider {
            .slick-track
            {
                display: flex !important;
            }

            .slick-slide
            {
                height: inherit !important;
                div:first-child:not(.label) {
                    height: 100%;
                }
                .btn {
                    min-height:40px;
                }
            }
            .slide {
                padding:0 15px;
            }
            .slick-dots {
                border-radius:35px;
                margin:0;
                padding:20px 50px;
                list-style: none;
                // background:rgba(255,255,255,.85);
                display:inline-block;
                overflow:hidden;
                position:absolute;
                bottom:-40px;
                left:50%;
                transform:translateX(-50%);
                // backdrop-filter: blur(15px);
                line-height:1;
                text-align:center;
                li {
                    display:inline-block;
                    padding:0;
                    margin:0;
                    button {
                        border:0;
                        padding:0;
                        margin:4px 10px 0;
                        background:$grey;
                        border-radius:100%;
                        width:16px;
                        height:16px;
                        text-indent:-9999px;
                    }
                    &.slick-active {
                        button {
                            background:$light-orange;
                            width:16px;
                            height:16px;
                            margin-top:0;
                        }
                    }
                }
            }
        }
    }
}

footer {
    background-color: $black;
    color:$white;
    .container{
        padding: 70px 70px 70px 130px;
    }
    hr {
        height:1px!important;
        background:$white;
        margin:20px 0;
        width:50%!important;
    }
    h5 {
        font-size:16px;
        font-weight:700;
        text-transform: uppercase;
        margin:30px 0 20px;
    }

    ul {
        margin:0;
        padding:0;
        list-style:none;
        li {
            a {
                color:$white;
                text-decoration: none;
                &:hover {
                    color:$light-orange;
                }
            }
        }
    }
}


.product-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position:relative;
    margin: 5px 0;
    padding: 15px;
    background-color: $white;
    border-radius: 25px;
    height:100%;
    // min-height:465px;
    // overflow:hidden;
    .prices {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:10px;
        margin-bottom:10px;
    }
    .label {
        color: $white;
        font-size: 18px;
        font-weight: 700;
        padding: 5px 15px 5px 15px;
        border-radius: 0 15px 15px 0;
        margin-left:-25px;
        text-transform: uppercase;
        display:inline-block;
        position:absolute;
        top:20px;
        &::after{
            position:absolute;
            bottom:-10px;
            left:0;
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 0;
            
        }
        &.label-new {
            background: $light-green;
            color: $white;
            &::after {
                border-color: transparent $dark-green transparent transparent;
            }
        }
        &.label-sale {
            background: $light-red;
            color: $white;
            &::after {
                border-color: transparent $dark-red transparent transparent;
            }
        }
        &.label-preorder {
            background: $light-purple;
            color: $white;
            &::after {
                border-color: transparent $dark-purple transparent transparent;
            }
        }
        &.label-exclusive {
            right: -28px;
            top: 0;
            &::after {
                display:none;
            }
        }
        &.label-black {
            background: $black;
            color: $light-orange;
            &::after {
                border-color: transparent #ab700c transparent transparent;
            }
        }
        &.label-blue {
            background: $label-blue-light;
            color: $white;
            &::after {
                border-color: transparent $label-blue-dark transparent transparent;
            }
        }

    }
    
    .image {
        background: $gradient-grey;
        border-radius: 25px;
        // box-shadow: 0 5px 6px rgba(0,0,0,.1);
        margin-bottom: 15px;
        overflow:hidden;
        min-height:330px;
        display:flex;
        align-items: center;
        justify-content: center;
        img {
            width: 290px;
            height: 330px;
            object-fit: contain;
            margin:5px;
            // max-width:205px;
        }
    }
    .product-name {
        font-size: 20px;
        font-weight: 600;
        display: block;
        // margin-bottom: 50px;
        // min-height:90px;
        text-decoration: none;
        color: $black;
        transition:.3s all ease-out;
        text-align:center;
        &:hover {
            color: $light-orange;
        }
    }
    .price {
        color: $light-orange;
        font-size: 22px;
        font-weight: 900;
        &.old_price {
            color: #cecece;
            font-size: 18px;
            text-decoration: line-through;
            clear: both;
            display: block;
            text-decoration-color:$light-red;
            font-style: italic;
            padding-left: 10px;
        }
        &.sale_price {
            color:$light-red;
        }
    }
    .btn-add-to-cart {
        float: right;
        font-size: 12px;
        font-weight: 800;
        // margin-top: 10px;
        // display:block;
        width: 100%;
        text-align:left;
        padding:0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color:#fff;
    }
}

.section-title {
    font-weight: 900;
    font-size: 25px;
}

hr {
    height: 3px!important;
    opacity: 1;
    background-color:#D1D1D1;
    margin: 5px 15px 0;
    margin-top: 5px!important;
    width: calc(100% - 30px)!important;
}

.btn {
    color: $black;
    font-weight:800;
    text-transform:uppercase;
    border-radius:15px;
    line-height:1;
    padding:0 12px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height:40px;
    &:hover{
        .btn-link {
            left:initial;
        }
    } 
}

.btn-long {
    padding-left:50px;
    padding-right:50px;
}

.btn-orange, .btn-primary {
    background: $gradient-orange;
}

.btn-dark-orange {
    // background: $gradient-dark-orange;
    background: linear-gradient(0,#f06115,#f18916);
}

.btn-primary {
    .btn-icon {
        display:none;
    }
    .btn-link {
        position:relative;
        color: $black;
        font-weight:800;
        text-transform:uppercase;
        border-radius:15px;
        line-height:1;
        padding:12px;
        top:initial;
        left:initial;
        transform:none;
        font-size:14px;
        &:hover {
            left:initial;
        }
    }
}

.btn-gray {
    background-color: $lighter-grey;
}

.dropdown-toggle::after{
    color:$light-orange;
}

.dropdown-toggle.without-icon:after {
    display:none;
}

.dropdown-toggle.without-icon {
    cursor:pointer;
}

.dropdown-item {
    color: $white;
    &:hover, &:focus {
        color: $white;
        background-color: $light-orange;
    }
}

.text-orange {
    color:$light-orange;
}

.btn-black {
    background: $gradient-black-bottom;
    color: $white;
    border-radius:15px;
    font-size:15px;
    font-weight:800;
    padding:12px;
    text-transform: uppercase;
    border:0;
}

.dropdown-menu {
    background-color:$black;
    
    &.megamenu {
        border:0px;
        line-height:1.5;
        ul {
            li {
                padding:2px 6px;
                a{
                    font-weight:400;
                }
            }
        }
    }
}



.navbar {
    padding-top:0;
    padding-bottom:0;
    .megamenu{ 
        padding: 2.5rem 10.5rem; 
    }
}

.dropdown.has-megamenu:hover .dropdown-menu {
    display: block;
    top:100%;
 }

 .navbar-toggler:focus {
    box-shadow:none;
 }

 .navbar-toggler[aria-expanded=true] .bi-list {
    display:none;
 }

 .navbar-toggler[aria-expanded=true] .bi-x-lg {
    display:initial!important;
 }

 .user-info {
    color:$white;
    font-size:16px;
    font-weight:600;
    float:right;
    display:flex;
    &.mobile {
        display:none;
        .email {
            float: none;
        }
        .dropdown-menu {
            left:0;
            right:auto;
            top:140px;
            border:1px solid;
        }
    }
    .email {
        color:$light-orange;
        font-size:12px;
        display: block;
        float: none;
    }
    .avatar{
        height: 42px;
        border: 2px solid transparent;
        border-radius: 100%;
        float:left;
        margin-right:10px;
    }
    .dropdown-menu {
        left:auto;
        right:0;
    }
 }

.featured-products {
    .product-box {
        min-height:initial;
    }
}


#notifyModal, #newsletter-signup {
    .modal-dialog {
        max-width:550px;
    }
    .modal-content {
        background:$white-dark;
        h5 {
            font-size:27px;
            font-weight:800;
        }
        .modal-header {
            border-bottom:0px;
        }
        .modal-body{
            padding:32px 85px 80px;
        }
        .form-control,.form-select {
            border-radius: 15px;
            line-height: 42px;
            margin-top: 10px;
        }
        .form-control {
            max-width:275px;
            margin-right:10px;
            display:inline-block;
        }
        button {
            // height: 56px;
            padding: 6px 10px;
            color: #fff;
        }
    }
}

#newsletter-signup {
    .modal-dialog {
        max-width:600px;
    }
    .modal-content {
        background:$white-dark;
        border: 10px solid $dark-orange;
    }
}



@media all and(min-width:767px) and (max-width:1024px) {
    .slide {
        &.slide-dark, &.slide-light {
            .row .slide-left {
                justify-content: center;
            }
        }
    }
}

@media all and(min-width:1024px) and (max-width:1600px) {
    .slide {
        &.slide-dark, &.slide-light {
            .row .slide-left {
                padding-left:80px;
                div {
                    min-width:470px;
                    max-width:470px;
                }
            }
        }
    }
}

@media all and (max-width:768px) {
    .product-box {
        margin: 25px 5px;
    }
    .featured-product-slider {
        .product-box {
            margin:25px 0px;
        }
    }
   
    section.slider{
        max-height:525px!important;
        .slide {
            background-position: top center!important;
            background-size:contain;
            min-height:525px;
            .row {
                min-height:425px;
                align-items: flex-end !important;
            }
            .container {
                max-width:100%;
            }
            &.slide-dark, &.slide-light {
                
                .row .slide-left {
                    padding-top:15px;
                    justify-content: center;
                    text-align:center;
                    // background-image:$gradient-black-2!important;
                    height: auto!important;
                    p {
                        br {
                            display:none;
                        }
                    }
                }
            }
            &.slide-dark {
                &:before {
                    right:0;
                    background:$gradient-black-2;
                }
            }
            &.slide-light {
                &:before {
                    background:$gradient-white-2!important;
                }
            }
            img {
                margin-left:auto;
                margin-right:auto;
                margin-bottom:1.5rem;
                max-width:250px;
                width:100%;
            }
        }
    }
    section.cart {
        h5 {
            font-size:18px;
        }
        .price {
            font-size:25px;
        }
        .cart-title {
            margin:initial;
            margin-bottom:15px;
        }
        .cart-steps {
            li {
                width:16px;
                height:16px;
                &:nth-child(2) {
                    margin:0 8px;
                    &:before {
                        width:8px;
                    }
                    &:after {
                        width:8px;
                    }
                }
            }
        }
        .product-cart {
            .image {
                margin-left: initial;
            }
            button {
                top:5px;
                right:10px;
            }
        }
    }

    footer .container {
        padding: 50px;
    }


    .product-logo {
        display:none;
    }
    
    .product-player-time {
        margin-top:0!important;
        padding-top:0!important;
        box-shadow: none!important;
        padding:0 30px;
        .time-item {
            padding:15px 0;
            font-size:16px;
            img {
                width:auto;
                height:25px;
                display:block;
                margin: 0 auto!important;
            }
        }
    }

    section.product-short-desc {
        background-image:inherit!important;
        background-color:$white;
        padding-top:0;
        padding-bottom:0;
        font-size:0.875rem;
        .product-gallery-desc {
            padding:35px 15px 0;
            .price {
                font-size:25px;
            }
            .buttons {
                .form-select {
                    font-size:0.875rem;
                    line-height:35px;
                }
                .btn {
                    font-size:15px;
                    font-weight:600;
                    padding:10px 15px;
                }
            }
            .freeshipping-info {
                display:none;
            }
        }
    }

    section.product-description {
        .desc-title {
            padding-top:15px;
            padding-bottom:0;
            font-size:18px;
        }
        .desc-content {
            font-size:16px;
            ul {
                column-count: 1;
            }
            .download-item {
                margin-bottom:20px;
                img {
                    // float:initial;
                    // display:block;
                    margin:0 auto;
                    max-width:100px!important;
                }
                h5 {
                    font-size:18px;
                    text-align:center;
                }
                a{
                    float:right;
                }
            }
        }
    }
}



/* ============ desktop view ============ */
@media all and (min-width: 992px) {

  .navbar .has-megamenu{position:static!important;}
  .navbar .megamenu{left:0; right:0; width:100%; margin-top:0;  }

}	
/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
@media(max-width: 991px){
  .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
    overflow-y: auto;
      max-height: 90vh;
      margin-top:10px;
  }
}
/* ============ mobile view .end// ============ */

@media (max-width:1024px) {
    
    .product-short-desc {
        .product-gallery-desc {
            .slick-next, .slick-prev {
                background:transparent!important;
                padding:0!important;
            }
        }
        .slick-arrow {
            display:block!important;
        }
    }
    .slick-arrow {
        display:none!important;
    }

    .slide {
        .container {
            max-width:100%;
        }
    }

    .navbar .megamenu{ 
        padding: 1rem;
        // text-align:center; 
    }

    .product-description {
        .desc-content {
            iframe {
                height:auto!important;
            }
        }
    }
    
}

@media(max-width:768px) {
    section{
        &.slider {
            .slick-dots{
                padding:5px 10px;
                width: 100%;
                backdrop-filter: none;
            }
        }

        &.newsletter {
            padding:0 30px 30px;
            .container {
                padding:25px;
                // margin:0 15px;
                text-align:center;
            }
            img {
                max-width:100px;
            }
            h2 {
                font-size:20px!important;
            }
            p {
                font-size:18px!important;
            }
            button {
                margin-top:10px;
            }
            label {
                font-size:12px;
            }
        }
    }
    header {
        nav.main-menu {
            .universe-block {
                display:none;
            }
            .megamenu {
                padding:0;
                h5 {
                    font-size:14px;
                }
                ul {
                    margin:0;
                    padding-top:0!important;
                    border-top:0;
                    display:none;
                    li {
                        border:0;
                        line-height:35px;
                        a {
                            font-size:14px!important;
                            color:#737373;
                        }
                    }
                }
            }
        }
    }

    .user-info {
        display:none;
        &.mobile {
            display:block;
            float:initial;
            margin:15px;
        }
    }

    section.product-short-desc .product-gallery-desc .big-pic {
        a {
            height:100%;
            min-height: 280px;
            background-size: cover;
            background-position: center;
        }
        img {
            max-height: 280px;
            width:auto;
        }
    }

    

    #shopping-cart{
        h5 {
            font-size:16px;
        }
        .image {
            img {
                max-width:100%!important;
                width:80%;
                margin:10px!important;
            }
        }
        .cart-products-desc {
            font-size:12px;
        }
        .price {
            font-size:20px;
        }

        .btn {
            font-size: 18px;
            line-height: 40px!important;
        }

        .btn-white {
            margin-bottom: 15px;
        }

    }

    h2.section-title {
        font-size:20px;
        padding:0 30px;
    }

    hr {
        width:calc(100% - 60px)!important;
        margin: 5px 30px 0;
    }

    section.product-short-desc .product-player-time {
        padding:30px 30px 15px;
        padding-top:15px!important;
    }

    .product-player-time {
        .time-item:nth-child(2){
            border-left: 1px solid #eee;
            border-right: 1px solid#eee;
        }
    }

    .product-desc-title {
        padding-left:30px;
        padding-right:30px;
    }
}

@media(max-width:1199px) {
    header {
        .dropdown:not(.has-megamenu) {
            // float:initial;
            text-align:center;
            margin:15px;
        }
        .btn-cart {
            display:none!important;
            &.cart-small {
                display:inline-block!important;
                margin:0 0.25rem;
                img {
                    width:40px;
                }
                span {
                    left:19px;
                    bottom:-5px;
                }
            }
        }
        #navbarOptions {
            .row {
                // width:100%
            }
            .d-flex {
                display:block!important;
            }
            .btn-login {
                display:flex;
                float:none;
                clear:both;
                margin-bottom:15px;
                font-size:14px;
            }
            .dropdowns {
                .dropdown {
                    width:40%;
                    button {
                        min-width:100%;
                        font-size:14px;
                        &.btn-dropdown {
                            padding:10px 9px;
                        }
                    }
                }
                .dropdown:nth-child(2) {
                    float:right;
                }
            }
        }
        nav.main-menu {
            ul {
                margin:0 15px;
                li {
                    line-height:48px;
                    // text-align:center;
                    border-bottom:1px solid #313131;
                    a{
                        font-size:16px!important;
                    }
                   .expand-menu {
                        display:block;
                        float:right;
                   } 
                }
            }
        }
    }
}

@media(max-width:1550px) {
    header {
        .container-fluid {
            .row {
                min-height:initial;
            }
        }
        nav.main-menu {
            padding-top:0;
            padding-bottom:0;
            ul {
                li {
                     a {
                        font-size: 18px;
                     }
                }
            }
            .navbar-brand {
                img {
                    height:70px;
                }
            }
        }
    }

    section {
        &.slider{
            max-height:625px;
            // height:75vh;
            overflow:hidden;
        }
        &.newsletter {
            p{
                font-size:21px;
            }
        }
    }

    .g-5, .gx-5 {     
        --bs-gutter-x: 4rem;
    }

    h2.section-title {
        font-size:25px;
    }
}

@media(max-width:1280px) {
    section {
        &.product-short-desc {
            .product-gallery-desc {
                img.product-logo {
                    max-width:150px!important;
                }
                h2 {
                   font-size:20px; 
                }
                p {
                    font-size:16px;
                }
                .big-pic {
                    img {
                        max-height:300px;
                    }
                    a {
                        min-height:300px;;
                        max-height:300px;
                    }
                }
                .buttons {
                    .form-select {
                        line-height:30px;
                    }
                }
            }
            button {
                img {
                    max-height: 20px;
                }
            }

            .slick-prev, .slick-next {
                line-height:20px;
                padding:10px 20px;
            }

            .freeshipping-info {
                font-size:14px;
            }

            
            .product-player-time {
                padding: 15px;
                font-size:20px;
                img {
                    max-height:30px;
                }
            }
        }

        &.product-description {
            h2 {
                font-size:20px;
            }
            .desc-title {
                font-size:18px;
            }

            .desc-content {
                font-size:16px;
                .download-item {
                    h5 {
                        font-size:18px;
                        margin-top:0;
                    }
                }
            }
        }
    }

    section.slider {
        .slick-prev {
            left:15px;
        }
        .slick-next {
            right:15px;
        }
        .slick-prev, .slick-next {
            padding: 10px 26px;
        }

        .slick-dots {
            padding:10px 15px;
        }
        .slide {
            img {
                max-width:250px;
            }
            h2 {
                font-size:24px;
            }
        }
    }

    section.info{
        p {
            font-size: 16px;
            line-height: 50px;
        }
    }

    .product-box {
        .label {
            font-size:16px;
        }
        .product-name {
            font-size:18px;
        }
        .price {
            font-size:20px;
        }
    }

    h2.section-title {
        font-size:22px;
        padding-left:15px;
    }

    section.newsletter {
        h2 {
            font-size:30px;
        }
        p {
            font-size:18px;
        }
    }

}

.cart-products-desc {
    display:none;
}

#shopping-cart {
    font-family: 'Red Hat Display', sans-serif;
    h5 {
        font-weight:700;
        // font-size:27px;
        &.modal-title {
            // font-size:35px;
        }
    }
    .cart-products-desc {
        display:block;
    }
    .modal-content {
        background-color: $white-dark;
    }
    .modal-header {
        border-bottom:0;
    }
    #shopping-cart-inner {
        position:relative;
        .btn-close {
            position:absolute;
            right:25px;
        }
    }

    .price {
        color:$light-orange;
        font-size:35px;
        font-weight:700;
        line-height:1;
        &.old_price {
            color: #cecece;
            font-size: 18px;
            text-decoration: line-through;
            clear: both;
            display: inline;
            text-decoration-color:$light-red;
            font-style: italic;
        }
        &.sale_price {
            color:$light-red;
        }
    }

    .image {
        background: $gradient-grey;
        border-radius: 25px;
        box-shadow: 0 5px 6px rgba(0,0,0,.1);
        margin-bottom: 15px;
        overflow:hidden;
        img {
            display:block;
            margin:10px auto;
            max-width:180px;
        }
    }
    .col-md-4 {
        width: 41.66666667%;
    }
    .col-md-8 {
        width: 58.33333333%;
    }

    .btn {
        width: 100%;
        line-height: 88px;
        height: auto;
        border-radius: 25px;
        font-size: 20px;
        text-transform: none;
    }
    .btn-white {
        background-color: $white;
        border:5px solid $light-orange;
        margin-bottom:50px;
    }
}

.universes {
    a {
        position:relative;
        display: block;
        border-radius: 25px;
        overflow: hidden;
        .logo {
            max-width: 250px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
        }
    }
}

.form-group {
    margin:10px 0;
}

.logo-footer {
    max-width:86px;
}

.modal-backdrop {
    width:100%;
    height:100%;
}

.slick-dots {
    list-style:none;
    text-align: center;
    padding:0;
    margin:10px;
    li {
        display:inline-block;
        button {
            background:$white-dark;
            border: none;
            border-radius: 100px;
            text-indent: -999px;
            width: 16px;
            height: 16px;
            -webkit-appearance: none;
            margin: 5px;
            display:block;
        }    
        &.slick-active {
            button {
                background:$light-orange;
            }
        }
    }
}

header {
    .navbar-brand {
        img {
            max-height:80px;
        }
    }
    &.fixed-top {
        .navbar-brand {
            img {
                max-height:50px;
            }
        }
    }
}

.cart-empty {
    background:url(../../public/assets/img/basket-empty.png);
    background-repeat: no-repeat;
    background-size:contain;
    background-position:center;
}

label.error {
    color: red!important;
}

input.error {
    border:2px solid red!important;
}

@media (min-width: 1440px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1390px;
    }
}



//about-us
section.about-us-header {
    background-image:url('/storage/about-us/about-us-header-bg.png');
    background-repeat:no-repeat;
    background-size:100%;
    background-position: bottom center;
    height:80vh;
    min-height:720px;
    background-color:$dark-orange;
    h1 {
        font-size:70px;
    }
    p {
        font-size:30px;
    }
    .btn-light {
        display:none;
    }
}

section.about-us-mission {
    h2 {
        font-size:70px;
        font-weight:700;
    }
    p {
        font-size:18px;
    }
}

section.our-story{
    h3.text-big {
        font-size:131px;
        text-transform:uppercase;
        font-weight:700;
    }
    .border-orange {
        border: 16px solid $gradient-orange;
        border-radius: 100%;
        position:relative;
    }
    .timeline-img {
        &.text-right {
            img {
                margin-right: 72px;
            }
        }
    }
    .timeline-year {
        padding-bottom:100px;
        h3 {
            font-size:130px;
            font-weight:900;
            color: #efefef;
            &.text-orange {
                color: $light-orange
            }
        }
        p {
            font-size:18px;
            strong {
                font-size:21px;
                text-transform:uppercase;
            }
        }
    }
    .border-left {
        border-left: 2px solid #707070;
    }

    .line-left {
        position:relative;
        padding-right: 100px;
        &:before {
            content:"";
            position:absolute;
            top:134px;
            right:0;
            width:80px;
            height:2px;
            background:#707070;
            transform:translateY(-50%);
        }
        &:after {
            content:"";
            position:absolute;
            top:134px;
            right:0;
            width:44px;
            height:44px;
            background-color: $dark-orange;
            border-radius: 100%;
            transform:translate(50%, -50%);

        }
    }

    .line-right {
        position:relative;
        padding-left: 100px;
        &:before {
            content:"";
            position:absolute;
            top:134px;
            left:0;
            width:80px;
            height:2px;
            background:#707070;
            transform:translateY(-50%);
        }
        &:after {
            content:"";
            position:absolute;
            top:134px;
            left:0;
            width:44px;
            height:44px;
            background-color: $dark-orange;
            border-radius: 100%;
            transform:translate(-50%, -50%);

        }
    }

    .building {
        .border-orange {
            padding:60px;
            border:2px solid #f7ac05;
            border-radius:40px;
            margin-top:80px;
            font-size:30px;
        }
    }

}

section.different {
    h2 {
        font-size:70px;
        font-weight:700;
        margin-bottom:60px;
    }
    img {
        margin-bottom:50px;
    }
    p {
        font-size:21px;
        padding-right:15px;
    }
}

section.join-our-flock {
    background-image: url('/storage/about-us/join-our-folk-bg.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size:cover;
    height:515px;
    padding-top:100px;
    padding-left:110px;
    h2 {
        font-size:110px;
        font-weight:700;
        text-transform:uppercase;
        margin-bottom:45px;
    }
    p {
        font-size:30px;
    }
    .btn {
        margin-top: 46px;
        margin-left: 88px;
        font-size: 20px;
        height: auto;
    }
}

.quantity-button__value {
    max-width:62px;
}

@media(max-width:1440px) {
    section.about-us-header {
        min-height:65vh;
        h1 {
            font-size:50px;
        }
        p {
            font-size:22px;
        }
    }
    section.about-us-mission {
        h2 {
            font-size:50px;
        }
        p {
            font-size:16px;
            br {
                display:none;
            }
        }
        img {
            margin:0 auto 40px;
            display:block;
        }
    } 

    section.our-story {
        img {
            &.border-orange {
                display:block;
                margin:0 auto 40px;
                max-width: 250px;
                width:100%;
            }
        }
        h3 {
            &.text-big {
                font-size:75px;
            }
        }
       .timeline-year {
            h3 {
                font-size:70px;
            }
            strong {
                font-size:18px;
            }
            p {
                font-size:16px;
            }
        }
        .line-right, .line-left {
            &:after {
                width:32px;
                height:32px;
                top:70px;
            }
            &:before {
                top:70px;
            }
        }


        .building {
            .border-orange {
                font-size: 18px;
                padding:40px;
                margin-top:50px;
            }
        }
    }

    section.different {
        h2 {
            font-size:50px;
        }

        img {
            margin:0 auto 50px;
            display:block;
        }

        p {
            font-size:16px;
            margin-bottom:50px;
        }
    }
}

@media (min-height:400px) and (max-width: 1100px) {
    section.about-us-header {
        min-height:550px;
    }
}

@media(max-width:1024px) {
    section.about-us-header {
        height:65vh;
        min-height:475px;
        h1 {
            font-size:50px;
        }
        p {
            font-size:22px;
        }
    }
    section.about-us-mission {
        h2 {
            font-size:40px;
        }
        p {
            font-size:16px;
            br {
                display:none;
            }
        }
        img {
            margin:0 auto 40px;
            display:block;
        }
    } 

    section.our-story {
        img {
            &.border-orange {
                display:block;
                margin:0 auto 40px;
                max-width: 250px;
                width:100%;
            }
        }
        h3 {
            &.text-big {
                font-size:70px;
            }
        }
        .border-left {
            border:0;
        }
        .line-right, .line-left {
            border:0;
            padding-right:12px;
            padding-left:12px;
            &:after, &:before{
                display:none;
            }
        }

        .timeline-img {
            order:0;
            text-align:right;
            img {
                // transform:translateX(-50%);
                margin-left:0!important;
                margin-right:0!important;
            }
            
        }
        .timeline-year {
            order:1;
            &.text-right {
                text-align:left!important;
            }
            h3 {
                font-size:90px;
            }
        }

        .building {
            .border-orange {
                font-size: 24px;
                padding:40px;
                margin-top:0;
            }
        }
    }

    section.different {
        h2 {
            font-size:50px;
        }

        img {
            margin:0 auto 50px;
            display:block;
        }

        p {
            font-size:18px;
            margin-bottom:50px;
        }
    }
}

@media(max-width:640px) {
    section.our-story {
        h3 {
            &.text-big {
                font-size:50px;
            }
        }
        .timeline-year {
            h3 {
                font-size:50px;
            }
        }
        .building {
            .border-orange {
                font-size:18px;
            }
        }
    }
}

#imagelightbox {
    background:#fff;
}

// @media(max-width:1080px) and (max-height:500px) {
//     .slick-slide {
//         img {
//             width:60%;
//         }
//     }

// } 

.badge-secondary {
    background-color: green;
}

.page-item.active .page-link {
    background-color: $light-orange;
    border-color: $light-orange;
    color: #fff;
    z-index: 3;
}

.page-link {
    color:$black-light;
}

.page-link:focus, .page-link:hover {
    color:$black-light;
}

.bg-orange {
    background:$gradient-orange-right;
}

.pos-rel {
    position:relative;
}

.search {
    background:#000;
    position:absolute;
    right:100%;
    border:1px solid $light-orange;
    border-radius:15px;
    padding:0 9px;
    line-height:38px;
    margin-right: 15px;
    box-shadow: 0 0 1px 0px $light-orange inset, 0 0 1px 0px $light-orange;
    
    form{
        input {
            border:0;
            background:transparent;
            color:#fff;
            transition:all 0.3s ease-out; 
            &:focus {
                border:0;
                outline: none;
            }
            &.hide {
                width:0;
                padding:0;
                margin-right:0;
            }
            &.show {
                width:175px;
                margin-right:9px;
            }
        }
    }

    .btn-search {
        cursor:pointer;
    }
    &.search-mobile {
        right:130px;
        margin-right:0;
        form{
            input {
                &.show {
                    width:115px;
                    margin-right:9px;
                }
            }
        }
    }
}