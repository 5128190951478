.product {
  &.universe {
    .product-image {
      height: 480px;
      @media (max-width: $sm) {
        height: 320px;
      }
    }
  }

  &.small-universe {
    .product-image {
      height: 380px;
      @media (max-width: $sm) {
        height: 320px;
      }
    }

    .btn-arrow {
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .product-image {
    position: relative;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 480px;
    @media (max-width: $sm) {
      height: 240px;
    }
    @media (min-width: $xxl) {
      height: 660px;
    }

    &.smaller-image {
      height: 180px;
      @media (max-width: $sm) {
        height: 240px;
      }
      @media (min-width: $xxl) {
        height: 270px;
      }
    }

    .image-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);
        pointer-events: all;
        opacity: 1;
        // @include transition(all 250ms ease-in-out);
      }
    }

    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 55%;
      z-index: 5;
      @media (max-width: $sm) {
        max-width: 75%;
      }

      img {
        width: 100%;
      }
    }

    .background {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate(-50%, -50%);
      // @include transition(all 250ms ease-in-out);
    }

    &:hover {
      .image-link {
        &:after {
          opacity: 0;
        }
      }

      .background {
        width: 105%;
        height: 105%;
      }
    }
  }

  .product-details {
    background: $color-white;
    padding: 25px 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .product-title {
      font-size: 16px;
      font-weight: 700;
      padding: 0;
      margin: 0 0 3px;
      position: relative;

      .product-link {
        color: $color-dark;
        text-decoration: none;

        .nav-text {
          position: relative;
          z-index: 1;

          &:after {
            content: '';
            width: 0;
            height: 6px;
            background: darken($color-white, 10%);
            position: absolute;
            bottom: 4px;
            left: 0;
            z-index: -1;
            // @include transition(all 250ms ease-in-out);
          }

          &:hover {
            &:after {
              @media (max-width: $md) {
                display: none;
              }
              width: 100%;
            }
          }
        }
      }
    }

    .product-description {
      padding: 0;
      margin: 0;
      font-size: 14px;
      color: lighten($color-dark, 30%);
      font-weight: 600;
    }
  }
}

.product-list {
  .filters {
    background: $color-white;
    border-radius: 16px;
    padding: 15px 30px !important;

    svg {
      margin-top: -1px;
    }

    .form-group {
      margin-bottom: 0;
    }

    .form-check {
      float: left;
      width: 50%;
      padding: 0;

      input {
        &[type='radio'] {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }

        &:checked {
          + label {
            border: none;
            background: linear-gradient(0, $color-primary, $color-secondary);
            color: $color-white;
            line-height: 45px;
          }
        }
      }

      label {
        background: $color-white;
        border: 4px solid darken($color-white, 7.5%);
        box-sizing: border-box;
        width: calc(100% - 5px);
        font-size: 14px;
        font-family: $font-primary;
        font-weight: 600;
        float: left;
        height: 45px;
        line-height: 37px;
        color: darken($color-white, 45%);
        // @include transition(all, 250ms);

        &:hover {
          cursor: pointer;
          border: 4px solid $color-secondary;
          color: $color-secondary;
        }

        &.left-check-label {
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
        }

        &.right-check-label {
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        }

        &.active {
          border: none;
          background: linear-gradient(0, $color-primary, $color-secondary);
          color: $color-white;
          line-height: 45px;
        }
      }
    }

    .filters-title {
      padding: 0;
      margin: 0;
      font-size: 14px;
      float: left;
      font-family: $font-primary;
      position: relative;
      top: 9px;

      &.radio {
        top: 14px;
      }

      span {
        position: relative;

        &:after {
          @media (min-width: $md) {
            content: '';
            position: absolute;
            height: 40px;
            width: 2px;
            top: 50%;
            transform: translateY(-50%);
            right: -30px;
            background: darken($color-white, 5%);
          }
        }
      }
    }

    .dropdown {
      &.filters-dropdown {
        float: right;
        width: 50%;
        @media (max-width: $xs) {
          width: 100%;
        }
        background: $color-white;
        margin-top: -4px;

        .list {
          @media (max-width: $xs) {
            width: 100%;
          }

          ul {
            padding: 0;
            margin: 0;

            li {
              list-style-type: none;
            }
          }
        }

        .current {
          font-weight: 700;
          color: $color-dark;
        }
      }
    }

    .filters-label {
      font-weight: 700;
      color: $color-gray;
      font-family: $font-primary;
      font-size: 14px;
      position: relative;
      top: 5px;
      @media (max-width: $xs) {
        top: -12px;
        text-align: left;
      }

      &:after {
        @media (max-width: $xs) {
          content: ':';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -5px;
        }
      }

      @media (max-width: $md) {
        float: left;
      }
    }

    // Styles
    .dropdown {
      background-color: $gray_lighter;
      border: solid 1px $gray_light;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.0075);
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      float: left;
      font-size: 14px;
      font-weight: normal;
      height: $input_height;
      line-height: $input_height - 2;
      outline: none;
      padding-left: 18px;
      padding-right: 30px;
      position: relative;
      text-align: left !important;
      transition: all 0.2s ease-in-out;
      user-select: none;
      white-space: nowrap;
      width: auto;

      &:focus {
        background-color: darken($gray_lighter, 2%);
      }

      &:hover {
        background-color: darken($gray_lighter, 1%);
      }

      &:active {
        background-color: darken($gray_lighter, 2.5%) !important;
        border-color: darken($gray_light, 4%);
        box-shadow: 0 1px 4px rgba(0, 0, 0, .05) inset;
      }

      // Arrow
      &:after {
        height: 0;
        width: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid $color-primary;
        transform: origin(50% 20%);
        transition: all 0.125s ease-in-out;
        content: '';
        display: block;
        margin-top: -2px;
        pointer-events: none;
        position: absolute;
        right: 10px;
        top: 50%;
      }

      &.open {
        @extend :active;

        &:after {
          transform: rotate(-180deg);
        }

        .list {
          transform: scale(1);
          opacity: 1;
          pointer-events: auto;
        }

        .option {
          cursor: pointer;
        }
      }

      &.wide {
        width: 100%;

        .list {
          left: 0 !important;
          right: 0 !important;
        }
      }

      .list {
        box-sizing: border-box;
        transition: all .15s cubic-bezier(0.25, 0, 0.25, 1.75), opacity .1s linear;
        transform: scale(.75);
        transform-origin: 50% 0;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, .09);
        background-color: #fff;
        border-radius: $border_radius;
        margin-top: 4px;
        padding: 3px 0;
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 999;

        &:hover .option:not(:hover) {
          background-color: transparent !important;
        }
      }

      .option {
        cursor: default;
        font-weight: 400;
        line-height: $input_height - 2;
        outline: none;
        padding-left: 18px;
        padding-right: 29px;
        text-align: left;
        transition: all 0.2s;

        &:hover, &:focus {
          background-color: $gray_lighter !important;
        }

        &.selected {
          font-weight: 600;
        }

        &.selected:focus {
          background: $gray_lighter;
        }
      }
    }
  }

  .single-product {
    .product-image {
      @include aspect-ratio(1 1);
      position: relative;
      z-index: 2;

      a {
        width: 100%;

        img {
          max-width: 75%;
          width: 100%;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          // @include transition(all 250ms ease-in-out);

          &:hover {
            max-width: 80%;
          }
        }
      }
    }

    .product-details {
      position: relative;
      background: $color-white;
      padding-top: 120px;
      margin-top: -140px;

      &:before {
        content: '';
        width: 100%;
        height: 50px;
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        border-top-left-radius: 75px;
        border-top-right-radius: 75px;
        background: $color-white;
      }

      &:after {
        content: '';
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        border-bottom-left-radius: 75px;
        border-bottom-right-radius: 75px;
        background: $color-white;
        z-index: -10;
      }

      .product-title {
        font-weight: 700;
        font-size: 18px;
        overflow: hidden;
        height: 3em;
        a {
          &.product-link {
            color: $color-dark;
            text-decoration: none;

            .nav-text {
              position: relative;
              z-index: 1;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              &:after {
                content: '';
                width: 0;
                height: 6px;
                background: darken($color-white, 10%);
                position: absolute;
                bottom: 4px;
                left: 0;
                z-index: -1;
                // @include transition(all 250ms ease-in-out);
              }

              &:hover {
                &:after {
                  @media (max-width: $md) {
                    display: none;
                  }
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .product-description {
        color: $color-gray;
        overflow: hidden;
        height: 5em;
        span{
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .product-price {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 20px;
        padding-bottom: 0;
        margin-bottom: 0;

        .current-price {
          color: $color-primary;
          margin-right: 5px;
        }

        .old-price {
          color: red;
          font-weight: bold;
          text-decoration: line-through;
          margin-bottom: 0;
        }
      }
    }
  }
}

.main-game {
  position: relative;
  height: 80vh;
  margin-top: -30px;
  margin-bottom: 90px;
  overflow: visible;
  @media (min-width: $sm) {
    height: 768px;
  }
  @media (min-width: $lg) {
    height: calc(82vh - 60px);
  }
  @media (max-width: $md) {
    min-height: 100vh
  }

  .game-background {
    position: absolute;
    width: 110%;
    height: 100%;
    //margin-top: -5%;
    margin-left: -5%;
    object-fit: cover;
    z-index: 1;
  }

  .game-person {
    position: absolute;
    bottom: 0;
    left: 50%;
    @media (max-width: $sm) {
      margin-left: -30%
    }
    z-index: 2;
    height: 100%;
    width: auto;
  }

  .game-logo {
    position: absolute;
    top: 24%;
    left: 15%;
    transform: translateY(-25%);
    z-index: 4;
    max-width: 480px;
    @media (max-width: $md) {
      left: 0;
      width: 100%;
    }
    @media (max-width: $lg) {
      display: none;
    }

    @media (min-width: $lg) and (max-width: $xxxl) {
      max-width: 280px;
    }
  }

  .game-details {
    border-top: 2px solid lighten($color-dark, 10%);
    background: linear-gradient(0deg, rgba(25, 25, 25, 1) 0%, rgba(25, 25, 25, 0.7) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 4;
    padding: 60px 0;
    display: flex;
    align-items: center;

    @media (max-width: $md) {
      top: 0;
    }

    @media (min-width: $lg) and (max-width: $xxxl) {
      padding: 20px 0;
    }

    .product-box {
      position: absolute;
      top: -210px;
      @media (max-width: $lg) {
        position: relative;
        top: initial;
      }

      @media (min-width: $lg) {
        left: 25px;
        max-width: 350px;
        filter: drop-shadow(-1px 5px 7px #2d2c2c);
        -webkit-filter: drop-shadow(-1px 5px 7px #2d2c2c);
        -moz-filter: drop-shadow(-1px 5px 7px #2d2c2c);
      }

      @media (min-width: $lg) and (max-width: $xxl) {
        max-width: 250px;
        top: -140px;
      }

    }

    .game-title {
      color: $color-white;
      font-size: 32px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .product-price {
      display: flex;
      flex-direction: row;
      justify-content: start;
      font-size: 20px;
      padding-bottom: 0;
      margin-bottom: 0;

      .current-price {
        color: $color-primary;
        margin-right: 5px;
      }

      .old-price {
        color: red;
        font-weight: bold;
        text-decoration: line-through;
        margin-bottom: 0;
      }
    }

    .product-details {
      display: flex;
      flex-direction: row;
      padding: 0;
      padding-top: 20px;
      margin: 0;
      position: relative;
      @media (max-width: $sm) {
        flex-direction: row;
      }
      @media (max-width: $md) {
        left: 0;
        padding: 30px 0;
        justify-content: center;
      }

      .details-icon {
        padding-right: 5px;
      }

      .details-text {
        font-weight: 600;
        font-family: $font-primary;
        color: rgba(255, 255, 255, .8);
        font-size: 14px;
        padding-left: 5px;
        padding-right: 30px;
        position: relative;
        top: 3px;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}

.product-description {
  .nav {
    &.nav-tabs {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      border-bottom: 0;

      .nav-item {
        width: 32.8%;

        &:first-child {
          .nav-link {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
          }
        }

        &:last-child {
          .nav-link {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
          }
        }

        .nav-link {
          background: $color-white;
          width: 100%;
          border: none;
          text-align: center;
          font-size: 15px;
          font-weight: 600;
          color: darken($color-gray, 25%);
          font-family: $font-primary;
          // @include transition(all, 250ms);
          padding: 15px 0;

          &.active {
            border: none;
            color: $color-white;
            background: linear-gradient(0, $color-primary, $color-secondary);
            color: $color-white;
          }
        }
      }
    }
  }

  .tab-content {
    h1, h2, h3, h4, h5, h6 {
      font-weight: 800;
      text-transform: uppercase;
      padding-bottom: 20px;
      font-family: $font-primary;
    }

    ul, li, p {
      font-size: 16px;
      color: darken($color-gray, 10%);
    }

    .general-informations {
      column-count: 2;
      column-gap: 30px;
      @media (max-width: $md) {
        column-count: 1;
        column-gap: 0;
      }
    }

    .gallery-carousel {
      .owl-dots, .owl-nav {
        display: none;
      }

      .product-gallery {
        position: relative;
        overflow: hidden;
        background: linear-gradient(0, $color-primary, $color-secondary);
        @include aspect-ratio(16 9);
        border-radius: 16px;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 103%;
          //height: 103%;
          object-fit: fill;
          // @include transition(all, 250ms);
        }

        &:after {
          opacity: 0;
          content: url('../img/magnifying-glass.svg');
          width: 30px;
          height: 30px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 4;
          // @include transition(all, 250ms);
        }

        &:hover {
          img {
            //height: 110%;
            width: 110%;
            opacity: .5;
          }

          &:after {
            opacity: 1;
            content: url('../img/magnifying-glass.svg');
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 4;
            pointer-events: all;
          }
        }
      }
    }

    .award-logo {
      max-width: 110px;
      max-height: 110px;
      margin-bottom: 12px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .award {
      margin-right: 50px;
      margin-bottom: 34px;

      @media (max-width: 263px) {
        margin-right: 0;
      }

      h6 {
        max-width: 110px;
        font-size: 0.7rem;
        text-align: center;
        font-weight: 700;
        padding-bottom: 0;
      }
    }

    //.awards-carousel {
    //  .owl-dots, .owl-nav {
    //    display: none;
    //  }
    //
    //  .award-logo {
    //    max-width: 80px;
    //    max-height: 80px;
    //    width: 100%;
    //    height: 100%;
    //    object-fit: cover;
    //    //filter: grayscale(100%);
    //  }
    //}
  }

  .video-container { position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; }
  .video-container iframe, .video-container object, .video-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

}

.download-rulebook {
  background: $color-white;
  padding: 30px;
  border-radius: 16px;

  .rulebook-cover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    max-width: 220px;
    max-height: 200px;
    width: auto !important;
  }

  .title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 800;
    font-family: $font-primary;
  }

  .description {
    font-size: 14px;
    color: darken($color-gray, 10%);
  }

  .dropdown {
    &.filters-dropdown {
      float: left;
      width: 100%;
      @media (max-width: $xs) {
        width: 100%;
      }
      background: $color-white;

      .list {
        width: 100%;

        ul {
          padding: 0;
          margin: 0;

          li {
            list-style-type: none;
          }
        }
      }

      .current {
        font-weight: 700;
        color: $color-dark;
      }
    }
  }
}

.single-quote {
  background: $color-white;
  padding: 50px;
  border-radius: 16px;

  .quote-logo {
    max-width: 100px;
    max-height: 100px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .title {
    padding-bottom: 0 !important;
    font-size: 18px;
  }

  .author {
    color: $color-primary;
    font-style: italic;
    font-weight: 500;
  }

  &:after {
    content: url('../img/quote-icon.svg');
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50px;
    right: 50px;
    z-index: 1;
  }
}

#no-game {
  background-color: #d90000;
  padding: 17px 0;
  margin-bottom: 90px;
  h5 {
    font-weight: bold;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 0;
    font-style: italic;
    letter-spacing: 0.8px;
  }
  a {
    font-weight: bold;
    text-decoration: underline;
    color: #fff;
  }
}

.no-game-height {
  @media (min-width: $lg) {
    height: calc(100vh - 146px) !important;
  }
}

.game-label {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  img {
    max-width: 40% !important;
    position: absolute;
    z-index: 1000;

    &.top_left {
      top: 15px;
      left: 20px;
    }

    &.top_right {
      top: 15px;
      right: 20px;
    }

    &.bottom_left {
      bottom: 15px;
      left: 20px;
    }

    &.bottom_right {
      bottom: 15px;
      right: 20px;
    }
  }

  &.popup-game-label {
    img {
      max-height: 50px;
      &.top_left {
        top: -3px;
        left: 2px;
}
      &.top_right {
        top: -3px;
        right: 2px;
      }

      &.bottom_left {
        bottom: -3px;
        left: 2px;
      }

      &.bottom_right {
        bottom: -3px;
        right: 2px;
      }
    }
  }
}

