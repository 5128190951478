.cart {
    @media (max-width: 767px) {
        max-width: 100% !important;
    }
    .back-button {
        max-width: 400px;
    }
    &__finish {
        &--row {
            border-top: 2px solid #F5F5F5;
            border-bottom: 2px solid #F5F5F5;
            padding: 10px 0;
            margin-top: -2px;
        }
        &--text {
            font-family: $font-primary;
            font-size: 16px;
            font-weight: 600;
        }
    }
    &__page {
        margin-top: 75px;
        padding: 0 30px;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        &--title {
            font-family: $font-primary;
            font-size: 36px;
            font-weight: 700;
        }
        &--indicators {
            list-style: none;
            padding: 0;
            margin-bottom: 2px;
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-left: auto;
             :nth-child(2) {
                margin: 0 30px;
            }
             :nth-child(2)::before {
                content: "";
                height: 3px;
                position: absolute;
                right: 15px;
                bottom: 3px;
                width: 25px;
                background: darken($color-bg, 5%);
                border-radius: 13px;
            }
             :nth-child(2)::after {
                content: "";
                height: 3px;
                position: absolute;
                left: 15px;
                bottom: 3px;
                width: 25px;
                background: darken($color-bg, 5%);
                border-radius: 13px;
            }
            .active {
                background: $color-primary !important;
                border: 3px solid $color-primary !important;
            }
            .step {
                position: relative;
                display: inline-block;
                height: 15px;
                width: 15px;
                border-radius: 15px;
                border: 3px solid darken($color-bg, 5%);
            }
        }
    }
    &__header {
        margin-bottom: 25px;
        padding: 0 16px;
        &--text {
            font-family: $font-primary;
            font-size: 20px;
            font-weight: 700;
            @media (max-width: 991px) {
                display: flex;
                span {
                    background: $color-bg;
                    z-index: 1;
                    padding-right: 15px;
                }
                &::after {
                    content: "";
                    display: inline-block;
                    flex: 0 0 100%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 3px;
                    background: darken($color-bg, 5%);
                    top: 10px;
                }
            }
        }
    }
    &__container {
        display: flex;
        flex-direction: column;
        @media (max-width: 991px) {
            margin-bottom: 45px;
        }
        &--item {
            background-color: white;
            border-radius: 16px;
            // height: 225px;
            width: 100%;
            margin: 11px 0;
            @media (max-width: 567px) {
                height: auto;
            }
        }
        &--item_small {
            background-color: white;
            border-radius: 16px;
            height: 150px;
            width: 100%;
            margin: 11px 0;
            @media (max-width: 567px) {
                height: auto;
            }
        }
        &--flex {
            display: flex;
            flex-direction: row;
            @media (max-width: 991px) {
                flex-direction: column;
            }
        }
        &--item_error {
            background-color: #F8D7DA;
        }
    }
    .order {
        padding: 16px;
        &__thumbnail {
            background-color: #F5F5F5;
            width: 194px;
            height: 194px;
            border-radius: 16px;
            flex: 0 0 194px;
            padding: 18px;
            display: flex;
            position: relative;
            @media (max-width: 991px) {
                width: auto;
            }
            &--img {
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }
        &__thumbnail_small {
            background-color: #F5F5F5;
            width: 120px;
            height: 120px;
            border-radius: 16px;
            flex: 0 0 120px;
            padding: 18px;
            display: flex;
            @media (max-width: 567px) {
                width: auto;
            }
            &--img {
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }
        &__description {
            display: flex;
            margin: auto 0;
            padding: 0 40px;
            flex: 1 1 auto;
            @media (max-width: 991px) {
                padding: 20px 0 0 0;
                //text-align: center;
            }
            @media (max-width: 767px) {
                &--title {
                    font-size: 16px !important;
                }
                &--subtitle {
                    font-size: 14px !important;
                }
                //&--price{
                //font-size: 18px !important;
                //}
            }
            &--container {
                width: 100%;
            }
            &--title {
                font-size: 22px;
                font-weight: 700;
                font-family: $font-primary;
            }
            &--subtitle {
                font-size: 16px;
                font-weight: 600;
                font-family: $font-secondary;
                color: $color-gray;
            }
            &--quantity {
                .finish-quantity {
                    font-size: 14px;
                    color: #949494;
                    display: block;
                }
                .quantity-button {
                    margin: 10px 0;
                    &__style {
                        height: 35px;
                        width: 35px;
                        background-color: #F5F5F5;
                        border: 0;
                        .fas {
                            color: #F5A00A;
                        }
                    }
                    &__decrease {
                        border-top-left-radius: 12px;
                        border-bottom-left-radius: 12px;
                    }
                    &__increase {
                        border-bottom-right-radius: 12px;
                        border-top-right-radius: 12px;
                    }
                    &__value {
                        height: 35px;
                        border: 2px solid #F5F5F5;
                        background: white;
                        text-align: center;
                        width: 40px;
                        font-size: 16px;
                        font-weight: 600;
                        font-family: $font-secondary;
                        color: $color-gray;
                        max-width: 40px;
                        @media (max-width: 567px) {
                            width: calc(100% - 70px - 10px);
                        }
                    }
                }
            }
            &--price {
                font-size: 18px;
                font-weight: 700;
                font-family: $font-primary;
                color: $color-primary;
            }
        }
        &__addon {
            width: 32px;
            flex: 0 0 32px;
            @media (max-width: 567px) {
                position: absolute;
                top: 30px;
                right: 30px;
            }
            &--close {
                background-color: #FF5151;
                border-radius: 6px;
                border: 0;
                height: 32px;
                width: 32px;
                i {
                    color: white;
                }
            }
        }
    }
    &__box {
        background: #fff;
        border-radius: 16px;
        padding: 28px;
        min-height: 410px;
        display: flex;
        flex-direction: column;
        .form-control {
            border: 2px solid #E6E7E8;
        }
        &--data {
            display: flex;
            flex-direction: column;
        }
        &--data-button {
            background: #fff !important;
            color: #F18B16 !important;
            border: 2px solid #F18B16 !important;
            .btn-link {
                color: #F18B16 !important;
            }
        }
        .form-check-label {
            font-size: 12px;
            font-weight: 600;
            color: #949494;
        }
        &--label {
            font-size: 16px;
            font-family: $font-primary !important;
            font-weight: 700;
        }
        &--header {
            font-family: $font-primary;
            font-size: 20px;
            font-weight: 700;
            margin-top: 20px;
            margin-bottom: 36px;
        }
        &--text {
            font-family: $font-secondary;
            font-size: 16px;
            color: #949494;
        }
        &--button {
            margin-top: auto;
        }
    }
    &__summary {
        overflow: initial !important;
        &--form {
            padding: 16px;
        }
        &--sticky-form {
            position: sticky !important;
            top: 100px;
            display: block;
        }
        &--value {
            font-family: $font-secondary;
            font-size: 18px;
            font-weight: 700;
            color: #949494;
            @media (max-width: 767px) {
                text-align: right;
            }
        }
        &--value-big {
            font-size: 24px;
            color: black;
        }
        &--text {
            font-family: $font-secondary;
            font-size: 12px;
            font-weight: 600;
            color: #949494;
            text-align: center;
            i {
                color: #F5A308;
                font-size: 16px;
                margin-right: 4px;
                margin-top: 3px;
            }
        }
        &--button {
            height: 80px !important;
            border-radius: 16px !important;
            font-size: 20px !important;
            font-weight: 700;
            .btn-link {
                font-size: 20px !important;
                margin-left: 15px;
            }
            .btn-icon {
                margin-right: 15px;
            }
        }
        .form-group {
            // display: flex;
            // flex-direction: column;
            .form-check {
                padding: 0 !important;
            }
            .form-check-label {
                font-size: 16px;
                font-weight: 600;
            }
            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
                position: absolute;
                left: -9999px;
            }
            [type="radio"]:checked+label,
            [type="radio"]:not(:checked)+label {
                position: relative;
                padding-left: 28px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                color: #666;
            }
            [type="radio"]:checked+label:before,
            [type="radio"]:not(:checked)+label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 20px;
                height: 20px;
                border: 1px solid #F18B16;
                border-radius: 100%;
                background: #fff;
            }
            [type="radio"]:checked+label:after,
            [type="radio"]:not(:checked)+label:after {
                content: '';
                width: 12px;
                height: 12px;
                background: #F18B16;
                position: absolute;
                top: 4px;
                left: 4px;
                border-radius: 100%;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
            [type="radio"]:not(:checked)+label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
            }
            [type="radio"]:checked+label:after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
            }
        }
        .form-divider {
            border-top: 4px dashed #E6E6E6;
            padding-top: 26px;
        }
        &--label {
            flex: 1 1 100%;
            font-size: 16px;
            font-weight: 700;
            font-family: $font-primary;
        }
        &--label-big {
            font-size: 18px;
            margin-bottom: -4px;
            font-weight: 700 !important;
        }
        &--info {
            flex: 0 0 20px;
            .far {
                font-size: 24px;
                color: #F5A00A;
            }
        }
        &--column {
            display: flex;
            flex-direction: column;
        }
        &--row {
            display: flex;
            flex-direction: row;
        }
        @media (max-width: 767px) {
            &--mobile-row {
                flex-direction: row !important;
            }
        }
        .cart-select {
            width: 100% !important;
            border: 2px solid #E6E7E8;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 600;
            font-family: $font-secondary;
            color: $color-gray;
            text-transform: initial !important;
            .list {
                width: 100%;
                ul {
                    padding: 0;
                    margin: 0;
                    li {
                        &.option {
                            padding: 0 24px;
                            list-style-type: none;
                        }
                    }
                }
            }
        }
        &--delivery {}
        select {
            &.form-dropdown {
                display: none;
                width: 100%;
            }
            .dropdown {
                height: 32px;
                line-height: 32px;
                width: 100%;
                background: $color-dark;
                border-color: $color-dark;
                 :active {
                    color: $color-dark;
                }
                &.open {
                    span {
                        &.current {
                            color: $color-dark;
                        }
                    }
                    ul {
                        li {
                            &.option {
                                color: $color-dark;
                            }
                        }
                    }
                }
                span {
                    &.current {
                        color: $color-white;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .many-warehouses {
        border: 1px solid #f18916;
        padding: 16px;
        border-radius: 7px;
        background: #fff;
        margin-bottom: 0;
    }
}

.secure-payment {
    background: #fff;
    &__header {
        font-size: 20px;
        font-weight: 600;
        font-family: $font-primary;
        i {
            color: #F18B16;
        }
    }
}

.cart-container {
    @media (max-width: 991px) {
        justify-content: center;
    }
    align-items: center;
}

#shopping-cart-count-inside {
    padding-right: 15px;
    margin-left: -5px;
}