@media (min-width: 1921px){
  .container-fluid{
    max-width: $fullhd;
    margin:0 auto;
  }
  .games-carousel{
    .container-fluid{
      max-width:100%;
    }
    .single-game .details .container-fluid{
      max-width: $fullhd;
    }
  }
  .main-game {
    max-height: 1080px;
  }
}