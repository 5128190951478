@import "~bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "slick-carousel/slick/slick.css";

@import "abstract/mixins";
@import "abstract/variables";
@import "abstract/modules";

/// Imports
// @import "header";
@import "footer";
@import "homepage";
@import "product";
@import "blog";
@import "page";
@import "cart";
@import "res4k";

@import "new_layout";
@import "dark_mode";
/// Font sizing
// H1 - H6

//@for $i from 1 through 6 {
//  h#{7 - $i} {
//    fontsize: $i * 1.4em;
//  }
//}

/// BasicError: pngquant-bin@4.0.0 postinstall: `node lib/install.js`

.app {
  // background: $color-bg;
  // font-family: $font-secondary;
  // font-size: 16px;
  // width: 100%;
  // display: flex;
  // flex-direction: column;
  // overflow-x: hidden;

  &.disabled {
    overflow: hidden;
  }
}

.content {
  // margin-top: 90px;
  // padding-bottom: 30px;
  height: 100%;
  width: 100%;
  // overflow-x: hidden;
  position: relative;
  z-index: 0;
  // @include transition(all 250ms ease-in-out);

  &.blurred {
    filter: blur(5px);
    // @include transition(all 250ms ease-in-out);
    overflow-x: hidden;
  }
}

.row {
  &-flex {
    display: flex;
    flex-wrap: wrap;
  }
}

.btn {
//   // font-family: $font-primary;
//   // font-weight: 500;
//   // font-size: 14px;
//   // height: 40px;

//   .disabled {
//     opacity: .65;
//   }

//   // .btn-link {
//   //   color: $color-white;
//   //   font-weight: 600;
//   //   font-size: 12px;
//   //   position: absolute;
//   //   left: 15px;
//   //   top: 50%;
//   //   transform: translateY(-50%);
//   //   text-decoration: none;
//   //   // @include transition(all 250ms ease-in-out);
//   // }

//   // .btn-icon {
//   //   position: absolute;
//   //   right: 15px;
//   //   top: 50%;
//   //   transform: translateY(-50%);
//   //   // @include transition(all 250ms ease-in-out);
//   // }

//   // &:hover {
//   //   .btn-link {
//   //     left: 20px;
//   //   }

//   //   .btn-icon {
//   //     right: 20px;
//   //   }
//   // }

  &-primary {
    // background: linear-gradient(0, $color-primary, $color-secondary);
    // color: $color-white;
    border: none;
    // border-radius: 5px;
    position: relative;
    text-decoration: none;
  }

//   // &.btn-orange {
//   //   color: $color-primary;
//   //   text-transform: uppercase;
//   //   background: $color-bg;
//   //   padding-left: 30px;
//   //   position: relative;
//   //   margin-top: -3px;
//   //   // @include transition(all 250ms ease-in-out);

    .btn-link {
      // font-weight: 700;
      // letter-spacing: 1.3px;
      text-decoration: none;
      // color: $color-primary;
      // @include transition(all 250ms ease-in-out);
    }

//   //   .btn-icon {
//   //     position: absolute;
//   //     top: 50%;
//   //     transform: translateY(-50%);
//   //     right: 0;
//   //     // @include transition(all 250ms ease-in-out);
//   //   }

//   //   &:hover {
//   //     text-decoration: none;

//   //     .btn-icon {
//   //       right: -15px;
//   //       // @include transition(all 250ms ease-in-out);
//   //     }
//   //   }
//   // }

//   &.btn-gray {
//     color: #fff;
//     background-color: #131313;
//     border-color: #131313;
//     border-radius: 10px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
}

.btn-orange-dark {
  background: linear-gradient(0, $orange_dark, $color-primary);
}

// .page-preloader {
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 998;
//   height: 100vh;
//   width: 100vw;
//   background: $color-dark;
//   display: block;
//   opacity: 1;

//   &:before {
//     content: '';
//     width: 100%;
//     height: 5px;
//     background: linear-gradient(90deg, $color-primary, $color-secondary);
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 999;
//     animation: width-100 0.75s ease-in-out;
//     animation-fill-mode: forwards;
//     @include transition(all 250ms ease-in-out);
//   }

//   .align-center {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//   }

//   .preloader-logo {
//     opacity: 1;
//     @include transition(all 250ms ease-in-out);
//     animation: shake-vertical 0.82s cubic-bezier(.36, .07, .19, .97) both;
//     transform: translate3d(0, 0, 0);
//   }

//   &.loaded {
//     height: 0;
//     opacity: 0;

//     &.disabled {
//       display: none;
//     }

//     @include transition(all 500ms ease-in-out);

//     .preloader-logo {
//       opacity: 0;
//     }

//     &:before {
//       opacity: 0;
//     }
//   }
// }

// .section-heading {
//   position: relative;
//   font-size: 24px !important;
//   font-family: $font-secondary;
//   font-weight: 800;
//   padding-bottom: 0;

//   &:after {
//     @media (max-width: $sm) {
//       content: '';
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//       width: 65%;
//       height: 55px;
//       background: $color-bg;
//       z-index: -1;
//     }
//   }

//   span {
//     &.main-title {
//       background: $color-bg;
//       @media (min-width: $md) {
//         padding-right: 30px;
//       }
//     }
//   }
// }

// .title-area {
//   position: relative;

//   &:before {
//     @media (max-width: $md) {
//       display: none;
//     }
//     content: '';
//     height: 30px;
//     width: 50px;
//     background: $color-bg;
//     position: absolute;
//     top: 50%;
//     left: 0;
//     transform: translateY(calc(-50% - 4px));
//     z-index: 0;
//   }

//   hr {
//     @media (max-width: 570px) {
//       display: none;
//     }
//     position: absolute;
//     width: calc(100% - 30px);
//     top: 50%;
//     left: 15px;
//     transform: translateY(-50%);
//     border-top: 2px solid darken($color-bg, 5%);
//     padding: 0;
//     margin-top: -3px;
//     z-index: -2;
//   }
// }

// /// Keyframes

// @keyframes width-100 {
//   0% {
//     width: 0;
//   }
//   100% {
//     width: 100%;
//   }
// }

// /// Dropdowns

// // Hide native dropdown
// select {
//   display: none;
// }

// // Styles
// .dropdown {
//   background-color: $gray_lighter;
//   border: solid 1px $gray_light;
//   box-shadow: 0 1px 0 rgba(0, 0, 0, 0.0075);
//   box-sizing: border-box;
//   cursor: pointer;
//   display: block;
//   float: left;
//   font-size: 14px;
//   font-weight: normal;
//   height: $input_height;
//   line-height: $input_height - 2;
//   outline: none;
//   padding-left: 18px;
//   padding-right: 30px;
//   position: relative;
//   text-align: left !important;
//   transition: all 0.2s ease-in-out;
//   user-select: none;
//   white-space: nowrap;
//   width: auto;

//   &:focus {
//     background-color: darken($gray_lighter, 2%);
//   }

//   &:hover {
//     background-color: darken($gray_lighter, 1%);
//   }

//   &:active {
//     background-color: darken($gray_lighter, 2.5%) !important;
//     border-color: darken($gray_light, 4%);
//     box-shadow: 0 1px 4px rgba(0, 0, 0, .05) inset;
//   }

//   // Arrow
//   &:after {
//     height: 0;
//     width: 0;
//     border-left: 4px solid transparent;
//     border-right: 4px solid transparent;
//     border-top: 4px solid $color-primary;
//     transform: origin(50% 20%);
//     transition: all 0.125s ease-in-out;
//     content: '';
//     display: block;
//     margin-top: -2px;
//     pointer-events: none;
//     position: absolute;
//     right: 10px;
//     top: 50%;
//   }

//   &.open {
//     @extend :active;

//     &:after {
//       transform: rotate(-180deg);
//     }

//     .list {
//       transform: scale(1);
//       opacity: 1;
//       pointer-events: auto;
//     }

//     .option {
//       cursor: pointer;
//     }
//   }

//   &.wide {
//     width: 100%;

//     .list {
//       left: 0 !important;
//       right: 0 !important;
//     }
//   }

//   .list {
//     box-sizing: border-box;
//     transition: all .15s cubic-bezier(0.25, 0, 0.25, 1.75), opacity .1s linear;
//     transform: scale(.75);
//     transform-origin: 50% 0;
//     box-shadow: 0 0 0 1px rgba(0, 0, 0, .09);
//     background-color: #fff;
//     border-radius: $border_radius;
//     margin-top: 4px;
//     padding: 3px 0;
//     opacity: 0;
//     overflow: hidden;
//     pointer-events: none;
//     position: absolute;
//     top: 100%;
//     left: 0;
//     z-index: 999;

//     &:hover .option:not(:hover) {
//       background-color: transparent !important;
//     }
//   }

//   .option {
//     cursor: default;
//     font-weight: 400;
//     line-height: $input_height - 2;
//     outline: none;
//     padding-left: 18px;
//     padding-right: 29px;
//     text-align: left;
//     transition: all 0.2s;

//     &:hover, &:focus {
//       background-color: $gray_lighter !important;
//     }

//     &.selected {
//       font-weight: 600;
//     }

//     &.selected:focus {
//       background: $gray_lighter;
//     }
//   }
// }

// /// Newsletter

// .newsletter {
//   background: linear-gradient(180deg, $color-primary, $color-secondary);
//   padding: 150px 0;
//   position: relative;

//   &:before {
//     @media (max-width: $md) {
//       width: 60vw;
//     }
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 30vw;
//     height: 100%;
//     background: #F18C05;
//     clip-path: polygon(0 0, 50% 0, 100% 100%, 0% 100%);
//   }

//   .mailchimp-disclaimer {
//     padding-bottom: 0;
//     color: rgba(255, 255, 255, .7);
//   }

//   &.page {
//     padding: 30px 0;

//     .newsletter-subtitle {
//       font-size: 20px;
//       @media (max-width: $sm) {
//         font-size: 18px;
//       }
//     }

//     .newsletter-title {
//       font-size: 38px;
//       padding-bottom: 30px;
//     }

//     .btn {
//       &.btn-dark {
//         .btn-link {
//           left: 35px !important;
//         }

//         .btn-icon {
//           right: 35px !important;
//         }

//         &:hover {
//           .btn-link {
//             left: 45px !important;
//           }

//           .btn-icon {
//             right: 45px !important;
//           }
//         }
//       }
//     }
//   }

//   .newsletter-subtitle {
//     color: $color-dark;
//     font-weight: 700;
//     font-size: 36px;
//     @media (max-width: $sm) {
//       font-size: 28px;
//     }
//   }

//   .newsletter-title {
//     color: $color-white;
//     font-weight: 700;
//     font-size: 62px;
//     @media (max-width: $sm) {
//       font-size: 36px;
//     }
//     @media (min-width: $xxxl) {
//       font-size: 72px;
//     }
//   }

//   .newsletter-description {
//     color: $color-white;
//   }

//   form {
//     input {
//       &.newsletter-input {
//         width: calc(100% - 7.5px);
//         height: 58px;
//         border: 0;
//         border-radius: 0;
//         background: rgba(0, 0, 0, .16);
//         color: $color-white;
//         padding: 0 15px;
//         font-weight: 600;
//         @include transition(all 250ms ease-in-out);
//         @media (max-width: $md) {
//           width: 100%;
//           border-radius: 16px;
//         }

//         &::placeholder {
//           color: rgba(255, 255, 255, .75);
//           @include transition(all 250ms ease-in-out);
//         }

//         &:focus,
//         &:active {
//           color: $color-white;
//           @include transition(all 250ms ease-in-out);
//         }

//         &.first-input {
//           border-top-left-radius: 16px;
//           border-bottom-left-radius: 16px;
//         }

//         &.second-input {
//           @media (max-width: $lg) {
//             border-top-right-radius: 16px;
//             border-bottom-right-radius: 16px;
//           }
//         }
//       }

//       &.newsletter-gdpr {
//         opacity: 0;

//         + label {
//           color: $color-white;
//           cursor: pointer;

//           a {
//             padding-bottom: 3px;
//             color: $color-white;
//             text-decoration: underline;
//             cursor: pointer;
//             @include transition(all, 300ms);

//             &:hover {
//               color: lighten($color-primary, 80%);
//               text-decoration: underline;
//               @include transition(all, 300ms);
//             }
//           }

//           &:before {
//             content: '\f111';
//             font-family: "Font Awesome 5 Free";
//             position: relative;
//             left: -10px;
//             opacity: 1;
//             color: $color-white;
//             @include transition(all, 300ms);
//           }
//         }

//         &:checked + label {
//           color: $color-white;

//           a {
//             color: $color-white;
//           }

//           &:before {
//             content: '\f192';
//             font-family: "Font Awesome 5 Free";
//             position: relative;
//             left: -10px;
//             opacity: 1;
//             @include transition(all, 300ms);
//           }
//         }
//       }

//       label {
//         color: $color-white;
//         cursor: pointer;
//         @include transition(all, 300ms);

//         a {
//           padding-bottom: 3px;
//           color: $color-white;
//           text-decoration: underline;
//           @include transition(all, 300ms);

//           &:hover {
//             color: $color-white;
//             text-decoration: none;
//           }
//         }
//       }
//     }

//     .btn {
//       height: 58px;
//       border-radius: 0;
//       border-top-right-radius: 16px;
//       border-bottom-right-radius: 16px;
//       @media (max-width: $lg) {
//         border-radius: 16px;
//       }
//       font-size: 16px;
//       text-transform: uppercase;

//       &:hover {
//         .btn-icon {
//           right: 45px;
//         }

//         .btn-link {
//           left: 45px;
//         }
//       }

//       .btn-link {
//         left: 35px;
//         @media (min-width: $lg) {
//           margin-top: -7px;
//         }
//       }

//       .btn-icon {
//         right: 25px;
//         @media (min-width: $lg) {
//           margin-top: -7px;
//         }

//         svg {
//           g {
//             path {
//               fill: $color-white;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// .carousel {
//   width: 100%;

//   .owl-stage-outer {
//     width: 100%;

//     .owl-stage {
//       width: 100%;
//       display: flex;

//       .owl-item {
//         display: flex;
//         opacity: .2;
//         @include transition(all, 300ms);

//         &.active {
//           opacity: 1;
//         }
//       }
//     }
//   }
// }

// @keyframes shake {
//   10%, 90% {
//     transform: translate3d(-1px, 0, 0);
//   }
//   20%, 80% {
//     transform: translate3d(2px, 0, 0);
//   }
//   30%, 50%, 70% {
//     transform: translate3d(-4px, 0, 0);
//   }
//   40%, 60% {
//     transform: translate3d(4px, 0, 0);
//   }
// }

// @keyframes shake-vertical {
//   10%, 90% {
//     transform: translate3d(0, -1px, 0);
//   }
//   20%, 80% {
//     transform: translate3d(0, 2px, 0);
//   }
//   30%, 50%, 70% {
//     transform: translate3d(0, -4px, 0);
//   }
//   40%, 60% {
//     transform: translate3d(0, 4px, 0);
//   }
// }

// .link-to-shopping-cart {
//   font-size: 1.7rem;
//   color: #fff;
//   transition: all 0.5s ease;

//   &:hover {
//     color: $primary;

//     .badge {
//       background-color: #fff;
//       color: $primary;
//     }
//   }

//   .badge {
//     font-size: 12px;
//     position: relative;
//     bottom: -7px;
//     right: 8px;
//   }
// }

// #shopping-cart-inner {
//   max-height: 70vh;
//   overflow-y: auto;

//   .ml-lg-auto {
//     margin-left: 0 !important;
//   }
// }


// #shopping-cart {
//   .modal-dialog {
//     @media (max-width: $lg) {
//       max-width: 650px !important;
//     }
//   }

//   .modal-body {
//     background: #F5F5F5;
//   }
// }


// .help-block {
//   color: red;
//   font-size: 13px;
//   margin-top: 3px;
// }

// .dropdown-account {
//   font-size: 1.7rem;
//   color: #fff;
//   transition: all 0.5s ease;

//   @media (max-width: $xl) {
//     padding: 15px 0;
//   }

//   &:hover {
//     color: $primary;
//     text-decoration: none;

//     .logged {
//       color: #fff;
//     }
//   }

//   &:after {
//     display: none;
//   }

//   .logged {
//     position: absolute;
//     bottom: 6px;
//     right: 5px;
//     color: forestgreen;
//     font-size: 12px;
//     transition: all 0.5s ease;
//   }
// }


.error-message {
  color: red;
  font-size: 12px;
  position: absolute;
  font-weight: bold;
  margin-top: 1px;
  margin-left: 2px;
  opacity: 0;
}

.error-message-v2 {
  color: red;
  font-size: 10px;
  position: absolute;
  bottom: -21px;
  right: 2px;
  font-weight: bold;
  margin-top: 1px;
  margin-left: 2px;
  opacity: 0;
}

// #update_profile_form .form-group {
//   position: relative;
// }

// .modal-content {
//   border: 0;

//   .modal-header {
//     background-color: #f49b0d;
//     color: white;
//   }
// }

.modal-background {
  background: $color-bg;
}

// .pagination {
//   display: flex;
//   justify-content: center;

//   li {
//     margin: 0 3px;
//     // height: 40px;
//     line-height: 40px;
//     text-align: center;
//     background: $light;
//     box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
//     @include border-radius(4px);

//     a {
//       color: $dark;
//     }

//     &.active {
//       background: $light-orange;
//       color: $light;
//     }

//     &:before {
//       display: none;
//     }
//   }
// }

// .old-price {
//   color: red;
//   font-weight: bold;
//   text-decoration: line-through;
// }

// .new-price {
//   color: #f49b0d;
//   margin-right: 10px;
// }

//  .lds-dual-ring {
//    display: inline-block;
//    width: 80px;
//    height: 80px;
//    z-index: 999999;
//  }

// .lds-dual-ring:after {
//   content: " ";
//   display: block;
//   width: 64px;
//   height: 64px;
//   margin: 8px;
//   border-radius: 50%;
//   border: 6px solid #fff;
//   border-color: #fff transparent #fff transparent;
//   animation: lds-dual-ring 1.2s linear infinite;
//   z-index: 999999;
// }

// @keyframes lds-dual-ring {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

// .StripeElement {
//   box-sizing: border-box;

//   height: 40px;

//   padding: 10px 12px;

//   border: 1px solid transparent;
//   border-radius: 4px;
//   background-color: white;

//   box-shadow: 0 1px 3px 0 #e6ebf1;
//   -webkit-transition: box-shadow 150ms ease;
//   transition: box-shadow 150ms ease;
// }

// .StripeElement--focus {
//   box-shadow: 0 1px 3px 0 #cfd7df;
// }

// .StripeElement--invalid {
//   border-color: #fa755a;
// }

// .StripeElement--webkit-autofill {
//   background-color: #fefde5 !important;
// }

// @import "darkmode";

// /* Responsive image and video in product description */
// .product-description img{
//   max-width: 100%;
//   height: auto;
// }

// .product-description video{
//   max-width: 100%;
//   height: auto;
// }

// /** Products mini list styles */
// .order__thumbnail__small{
//   background-color: #f5f5f5;
//   width: 48px;
//   height: 48px;
//   border-radius: 8px;
//   -webkit-box-flex: 0;
//   -ms-flex: 0 0 84px;
//   flex: 0 0 48px;
//   padding: 6px;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   position: relative;
// }

// .cart__mini{
//   background-color: #fff;
//   border-radius: 8px;
//   margin-top:8px;
//   margin-bottom: 8px;
// }

// .package-border{
//   border: 4px dashed #e6e6e6;
//   padding: 16px;
// }
/** Products mini list styles */

.badge-primary {
  color: #fff;
  background-color: #62a8ea
}

.badge-warning {
  color: #fff;
  background-color: #f2a654
}

.badge-danger {
  color: #fff;
  background-color: #f96868
}

.badge-dark {
  color: #fff;
  background-color: #526069
}

.badge-info {
  color: #fff;
  background-color: #57c7d4
}

.badge-success {
  color: #fff;
  background-color: #46be8a
}

