.games-carousel{
  height: 65vh;
  overflow: hidden;
  //margin-bottom: 90px;
  @media (max-width: $sm){
    height: 65vh;
  }
  @media (max-height: 520px){
    height: 140vh;
  }
  //@media (min-width: $sm){
  //  height: 85vh;
  //}
  @media (min-width: $md){
    //min-height: 700px;
    min-height: 550px;
    height: calc(82vh - 60px);
  }
  @media (max-width: $md){
    min-height: 600px;
  }
  @media (max-width: $sm){
    min-height: 400px;
    height: 400px;
  }
  .container-fluid{
    overflow: hidden;
  }
  .slide-progress{
    position:relative;
    width: 0%;
    max-width: 100%;
    height: 4px;
    background: $color-primary;
    margin-top:-4px;
  }
  .carousel{
    .owl-dots{
      position: relative;
      margin-top:-34px;
      text-align:center;
      button {
        appearance: none;
        border: 0;
        background: transparent;
        &:focus {
          outline:none;
        }
        &.active {
          span {
            background-color: $color-dark;
          }
        }
        span{
          display:inline-block;
          width:6px;
          height:6px;
          background-color:$color-primary;
          border-radius:100%;
        }
      }
    }
    .owl-nav{
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      .owl-prev, .owl-next{
        position: absolute;
        background: none;
        border: none;
        // @include transition(all, 250ms);
        &:hover{
          transform: scale(1.1);
        }
        &:focus, &:active{
          outline: none;
          box-shadow: none;
        }
        &.disabled{
          display: none;
        }
      }
      .owl-prev{
        left: 30px;
      }
      .owl-next{
        right: 30px;
      }
    }
    .owl-stage-outer{
      height: 100%;
      .owl-stage{
        height: 100%;
        padding: 0 !important;
        .owl-item{
          width: 100% !important;
          height: 100%;
          background: $color-dark;
          .single-game{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .single-game{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    .product-branding {
      position: absolute;
      top: -255px;
      left: 0;
      z-index: 5;
      h3{
        @media (min-width: 668px) and (max-width: 991px){
          display: none;
        }
      }
      @media (min-width: $lg) {
        top: -375px;
      }
      @media (max-width: $sm){
        top: -300px;
      }
      @media (max-width: $lg){
        width: 100%;
      }
      @media (max-height: 800px) and (min-width: 992px){
        top: -318px;
      }
      img{
        @media (min-width: 820px) and (max-width: 991px){
          margin-top: -60px;
        }
        @media (max-width: $lg){
          width: 100%;
        }
      }
      .btn-cta{
        @media (max-width: $sm){
          margin-top: 70px !important;
        }
      }
    }
    .product-box {
      position: absolute;
      right: 0;
      bottom: 200px;

      @media (max-width: $xxl) {
        bottom: 180px;
      }
      @media (max-width: $lg) {
        display: none;
      }
      width: 350px;

      .single-product {
        .product-image {
          @include aspect-ratio(1 1);
          position: relative;
          z-index: 2;

          a {
            width: 100%;

            img {
              max-width: 75%;
              width: 100%;
              height: auto;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              // @include transition(all 250ms ease-in-out);

              &:hover {
                max-width: 80%;
              }
            }
          }
        }

        .product-details {
          position: relative;
          background: $color-white;
          padding-top: 130px;
          margin-top: -160px;

          &:before {
            content: '';
            width: 100%;
            height: 50px;
            position: absolute;
            top: -50px;
            left: 50%;
            transform: translateX(-50%);
            border-top-left-radius: 75px;
            border-top-right-radius: 75px;
            background: $color-white;
          }

          &:after {
            content: '';
            width: 100%;
            height: 50px;
            position: absolute;
            bottom: -50px;
            left: 50%;
            transform: translateX(-50%);
            border-bottom-left-radius: 75px;
            border-bottom-right-radius: 75px;
            background: $color-white;
            z-index: -10;
          }

          .product-title {
            font-weight: 700;
            font-size: 24px;

            a {
              &.product-link {
                color: $color-dark;
                text-decoration: none;

                .nav-text {
                  position: relative;
                  z-index: 1;

                  &:after {
                    content: '';
                    width: 0;
                    height: 6px;
                    background: darken($color-white, 10%);
                    position: absolute;
                    bottom: 4px;
                    left: 0;
                    z-index: -1;
                    // @include transition(all 250ms ease-in-out);
                  }

                  &:hover {
                    &:after {
                      @media (max-width: $md) {
                        display: none;
                      }
                      width: 100%;
                    }
                  }
                }
              }
            }
          }

          .product-description {
            color: $color-gray;
          }

          .product-price {
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-size: 20px;
            padding-bottom: 0;
            margin-bottom: 0;

            .current-price {
              color: $color-primary;
              margin-right: 5px;
            }

            .old-price {
              color: red;
              font-weight: bold;
              text-decoration: line-through;
              margin-bottom: 0;
            }
          }
        }
      }

      @media (max-height: 800px) and (min-width: 992px) {
        bottom: 140px;
        .single-product .product-image{
          bottom: -1.5em;
        }

        .single-product .product-image a img {
          max-width: 55%;

          &:hover {
            max-width: 60%;
          }
        }

        .single-product .product-details:after {
          height: 30px;
          bottom: -30px;
        }

        .single-product .product-details:before {
          height: 30px;
          top: -30px;
        }
        .product-title {
          font-size: 18px;
        }
        .product-description {
          font-size:15px;
        }
        .product-description, .product-image {
          margin-bottom: 0;
        }

      }
    }
    .camera{
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      &:after{
        content: '';
        width: 150%;
        height: 150%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(140deg, rgba(19,19,19,.35) 0%, rgba(19,19,19,.65) 100%);
        z-index: 6;
      }
      .game-models{
        width: 100%;
        height: 100%;
        z-index: 2;
        @media (max-width: $sm){
          width: 120%;
          height: 120%;
          top: -10%;
          left: -10%;
        }
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        .foreground{
          width: 90%;
          margin: 0 5%;
          z-index: 2;
          @media (max-width: $sm){
            margin-top: 7% !important;
          }
          @media (max-width: $xl){
            width: 100%;
            margin: 0;
            margin-top: -7%;
          }
          @media (min-width: $xl){
            width: 70%;
            margin: 0 15%;
            margin-top: -4%;
          }
          @media (min-width: $xxl){
            width: 70%;
            margin: 0 15%;
            margin-top: -7%;
          }
        }
      }
      .background {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        position: relative;
        //filter: blur(1.5px);
        @media (max-width: $sm){
          width: 135%;
          height: 135%;
        }
      }
    }
    .camera2{
      
      &:after{
        content: '';
        width: 150%;
        height: 150%;
        position: absolute;
        top: 0;
        left: 0;
       background: transparent!important;
        z-index: 6;
      }
    }
    .details{
      position: absolute;
      bottom: 50px;
      width: 100%;
      left: 0;
      z-index: 6;
      .container-fluid {
        overflow: visible;
      }
      .short-details{
        background: $color-white;
        border-radius: 15px;
        @media (max-width: $sm){
          display: none;
        }
        .game-details{
          display: flex;
          flex-direction: row;
          padding: 0;
          margin: 0;
          position: relative;
          left: 30px;
          @media (max-width: $sm){
            flex-direction: column;
          }
          @media (max-width: $md){
            left: 0;
            padding: 30px 0;
            justify-content: center;
          }
          .details-icon{
            padding-right: 5px;
          }
          .details-text{
            font-weight: 600;
            font-family: $font-primary;
            color: $color-gray;
            font-size: 16px;
            padding-left: 5px;
            padding-right: 30px;
            position: relative;
            top: 3px;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
        .btn-cta{
          position: relative;
          right: -1;
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
          @media (max-width: $md){
            top: 2px;
            border-radius: 16px;
          }
          @media (min-width: $md){
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
          span{
            font-size: 16px;
            &.btn-link{
              left: 35px;
            }
            &.btn-icon{
              right: 35px;
            }
          }
        }
        .pt-40, .pb-40{
          padding-top: 40px;
          padding-bottom: 40px;
          @media (max-width: $sm){
            padding: 20px 0;
          }
        }
      }
    }
  }
}
.featured-games {
  margin-top: 40px;
}
