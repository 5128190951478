.blog{
  .blog-image{
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    @include aspect-ratio(4 3);
    @media (min-width: $md) and (max-width: $xl){
      @include aspect-ratio (4 2);
      border-top-left-radius: 60px;
      border-bottom-left-radius: 60px;
      border-top-right-radius: 0;
    }
    .image-link{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      &:after{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%);
        pointer-events: all;
        opacity: 1;
        // @include transition(all 250ms ease-in-out);
      }
    }
    .background{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate(-50%, -50%);
      // @include transition(all 250ms ease-in-out);
    }
    &:hover{
      .image-link{
        &:after{
          opacity: 0;
        }
      }
      .background{
        width: 105%;
        height: 105%;
      }
    }
  }
  .blog-details{
    background: $color-white;
    padding: 30px;
    padding-bottom: 60px;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    .text{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
    @media (min-width: $sm) and (max-width: $xl){
      border-bottom-left-radius: 0;
      border-top-right-radius: 60px;
      padding: 25px;
    }
    // &:after{
    //   content: url('../img/blog-element.svg');
    //   position: absolute;
    //   bottom: -50px;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   width: 380px;
    //   height: 50px;
    //   z-index: 0;
    //   @media (min-width: $sm) and (max-width: $xl){
    //     display: none;
    //   }
    //   @media (max-width: 330px){
    //     bottom: -44px;
    //   }
    // }
    .blog-date{
      color: $color-primary;
      font-size: 14px;
      font-weight: 700;
      padding-bottom: 10px;
    }
    .blog-title{
      font-size: 22px;
      font-weight: 700;
      padding: 0;
      margin: 0;
      margin-bottom: 10px;
      position: relative;
      .blog-link{
        color: $color-dark;
        text-decoration: none;
        .nav-text {
          position: relative;
          z-index: 1;
          &:after {
            content: '';
            width: 0;
            height: 6px;
            background: darken($color-white, 10%);
            position: absolute;
            bottom: 4px;
            left: 0;
            z-index: -1;
            // @include transition(all 250ms ease-in-out);
          }
          &:hover{
            &:after {
              @media (max-width: $md) {
                display: none;
              }
              width: 100%;
            }
          }
        }
      }
    }
    .blog-description{
      padding: 0;
      margin: 0;
      font-size: 14px;
      color: lighten($color-dark, 30%);
    }
  }

  .post-tags a {
    line-height: 42px;
    display: block;
    box-sizing: border-box;
    height: 44px;
    margin: 0 5px;
    padding: 0 10px;
    text-decoration: none;
    color: $color-primary;
    border: 2px solid $color-primary;
    border-radius: 22px;
    transition: background-color 0.3s, color 0.3s;
  }
  .post-tags a:hover {
    color: white;
    background: $color-primary;
  }

  .post-tags li {
    display: inline-block;
  }

  .post-tags {
    margin-block-start: 0px !important;
    padding-inline-start: 5px !important;
  }
}

#no-blog {
  background-color: #d90000;
  padding: 17px 0;
  margin-top: 25px;
  h5 {
    font-weight: bold;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 0;
    font-style: italic;
    letter-spacing: 0.8px;
  }
  a {
    font-weight: bold;
    text-decoration: underline;
    color: #fff;
  }
}
